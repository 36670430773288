<template>
  <div>
    <div v-if="formSendSuccess" class="py4">
      <p class="h1 open-sans-bold center">{{ $t("reviews_form_thank_you") }}</p>
      <p class="h5 open-sans-regular center mb3">
        {{ $t("reviews_form_verification_email_notice") }}
      </p>
      <div
        @click="modalRef.close()"
        class="rsf-btn rsf-btn-std color-white bg-color-1 open-sans-semibold mx-auto"
      >
        {{ $t("reviews_form_okay") }}
      </div>
    </div>
    <form
      v-if="!formSendSuccess"
      :class="`p2 ${sendingReview ? 'op50 pointer-events-none' : ''}`"
      @submit.prevent="submitReview"
    >
      <h2 class="open-sans-bold h1 mb3">
        {{ $t("reviews_form_leave_a_review") }}
      </h2>
      <!-- <p class="open-sans-regular color-14 mb2">{{ $t('reviews_form_subline') }}</p> -->

      <div class="form-field col-12 md-col-6 mb-24 max-w-324">
        <p class="open-sans-bold mb-11">
          {{ $t("reviews_form_choose_model") }}
        </p>
        <div
          class="rsf-select-wrapper mx-auto relative col-12 max-width-3 mb3 mobile-mx-auto"
        >
          <svg
            width="12"
            height="8"
            viewBox="0 0 12 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.7695 1.5L5.88481 6.5L1.00008 1.5"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <select
            required
            v-model="selectedModel"
            class="rsf-select h5 color-black open-sans-regular pointer"
            :style="`${
              selectedModel.Model == ''
                ? 'color: var(--rsf-2022-color-13) !important'
                : 'color: var(--rsf-2022-color-black) !important'
            }`"
          >
            <!-- Todo: automatically select previously chosen tank -->
            <option :value="{ Model: '', RNumber: '' }" disabled isSelected>
              {{ $t("reviews_form_select_tank") }}
            </option>
            <option
              v-for="model in modelsList"
              :key="model.RNumber"
              :value="model"
            >
              {{ model.Model }}
            </option>
          </select>
        </div>
      </div>

      <div class="form-field col-12 md-col-6 mb-24">
        <p class="open-sans-bold mb-11">
          {{ $t("reviews_form_feedback_rating") }}
        </p>
        <RateReview :updateRating="updateRating" />
      </div>

      <div class="form-field col-12 md-col-12 mb-24">
        <p class="open-sans-bold mb-11">
          {{ $t("reviews_form_title_notice") }}
        </p>
        <input
          required
          v-model="reviewTitle"
          class="rsf-input"
          type="text"
          placeholder="Your title"
        />
      </div>

      <div class="form-field mb-24">
        <p class="open-sans-bold mb-11">
          {{ $t("reviews_form_write_review_below") }}
        </p>
        <textarea required v-model="reviewText" class="rsf-textarea" rows="4">
          {{ $t("reviews_form_review_text_placeholder") }}
        </textarea>
        <p
          class="open-sans-regular color-14 size-span-text"
          style="line-height: 0.875rem"
        >
          {{ $t("reviews_form_min_char_notice") }}
        </p>
        <p
          class="open-sans-regular color-14 size-span-text"
          style="line-height: 0.875rem"
        >
          {{ $t("reviews_form_review_notice_1") }} <br />
          {{ $t("reviews_form_review_notice_2") }}
          <a
            class="underline color-1"
            target="_blank"
            rel="noopener"
            :href="`https://g1.redseafish.com/${
              siteLanguage == 'en' ? '' : siteLanguage + '/'
            }support/tech-support-inquiry-2021/`"
            >{{ $t("reviews_form_review_notice_3") }}</a
          >
          {{ $t("reviews_form_review_notice_4") }} <br />{{
            $t("reviews_form_review_notice_5")
          }}
          <br />
          {{ $t("reviews_form_review_notice_6") }}
          <a
            target="_blank"
            rel="noopener"
            :href="`https://s3.eu-central-1.amazonaws.com/one2five-prod/template/moderation-guidelines/${
              siteLanguage == 'de' ? 'de_de' : 'en_us'
            }/guidelines.html`"
            class="underline color-1"
            >{{ $t("reviews_form_review_notice_7") }}</a
          >{{ $t("reviews_form_review_notice_8") }}
        </p>
      </div>

      <!-- TODO: add radio styles -->
      <!-- <div class="radio-input-wrapper flex flex-row items-center gap2">
        <input required class="w-fit-content" type="radio" id="birthday_entertainment_false" name="birthday_entertainment_radio" value="birthday_entertainment_false">
        <label class="pointer" for="birthday_entertainment_false">Ohne Animation</label>
        <span class="radio-checkmark"></span>
      </div> -->

      <div class="form-field">
        <p class="open-sans-bold mb-11">
          {{ $t("reviews_form_would_recommend_label") }}
        </p>
        <!-- Radio -->
        <div class="flex md-flex">
          <div class="flex gap1 items-center mr3 radio-input-wrapper">
            <input
              v-model="isRecommended"
              class="rsf-input pointer"
              type="radio"
              value="true"
              id="check-1"
              name="radio-1"
            />
            <label for="check-1" class="open-sans-regular h5 pointer">{{
              $t("reviews_form_recommend_yes")
            }}</label>
            <span class="radio-checkmark"></span>
          </div>
          <div class="flex gap1 items-center radio-input-wrapper">
            <input
              v-model="isRecommended"
              class="rsf-input pointer"
              type="radio"
              value="false"
              id="check-2"
              name="radio-1"
            />
            <label for="check-2" class="open-sans-regular h5 pointer">{{
              $t("reviews_form_recommend_no")
            }}</label>
            <span class="radio-checkmark"></span>
          </div>
        </div>
        <div class="col-12 my4 bg-color-12 h1px"></div>
        <div class="form-field">
          <p class="open-sans-bold mb-11">
            {{ $t("reviews_form_add_media_label") }}
          </p>
          <!-- File Upload - still needs to be styled ;) -->
          <div class="relative">
            <drop-zone
              :updateFormFiles="updateFormFiles"
              :isUploading="uploadingFiles"
            />
            <div
              style="
                position: absolute;
                z-index: 2;
                width: 100%;
                height: 100%;
                top: 0;
                border-radius: 8px;
                display: grid;
                place-items: center;
              "
              :class="`${
                uploadingFiles ? 'op100' : 'op0 pointer-events-none'
              } bg-color-white color-12 border`"
            >
              <img
                class="block center mx-auto my2"
                src="https://static.redseafish.com/wp-content/uploads/2023/09/07130304/spinner2.gif"
              />
              <p class="open-sans-bolld center h5 color-14">
                {{ $t("reviews_form_upload_loader_caption") }}
              </p>
            </div>
          </div>
        </div>

        <div class="col-12 my4 bg-color-12 h1px"></div>

        <!-- Nickname -->
        <div class="form-field col-12 md-col-6 mb-24 max-w-324">
          <p class="open-sans-bold mb-11">{{ $t("reviews_form_nickname") }}</p>
          <input
            required
            v-model="reviewerName"
            class="rsf-input"
            type="text"
            :placeholder="$t('reviews_form_nickname')"
          />
          <span
            class="open-sans-regular color-14 size-span-text block"
            style="line-height: 15px"
            >{{ $t("reviews_form_name_notice") }}</span
          >
        </div>

        <div class="form-field col-12 md-col-6 mb2">
          <p class="open-sans-bold mb-11">
            {{ $t("reviews_form_email_label") }}
          </p>
          <input
            required
            v-model="reviewerEMail"
            class="rsf-input"
            type="email"
            placeholder="example@gmail.com"
          />
          <label class="open-sans-regular color-14 size-span-text">{{
            $t("reviews_form_email_notice")
          }}</label>
        </div>

        <label
          for="reviewerTerms"
          class="open-sans-regular color-14 pointer checkbox-input-wrapper flex pointer mb4"
          style="gap: 4px"
        >
          <div class="h-fit-content relative">
            <input
              required
              v-model="reviewerTerms"
              id="reviewerTerms"
              class="rsf-input"
              type="checkbox"
            />
            <span class="checkbox-checkmark"></span>
          </div>
          <span
            >{{ $t("reviews_form_read_tnc_label")
            }}<a
              :href="`https://redseafish.com/${siteLanguage}/reviews_terms/`"
              target="_blank"
              rel="noopener"
              class="color-1 open-sans-regular underline"
              >{{ $t("reviews_form_read_tnc_label_2") }}</a
            ></span
          >
        </label>
        <button
          :class="`rsf-btn rsf-btn-primary color-white bg-color-1 mx-auto mb3 mobile-mb1 ${
            formIsFilled ? '' : 'inactive'
          }`"
          type="submit"
        >
          {{ $t("reviews_form_submit_review_btn_label") }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import DropFile from "./DropFile.vue";
import RateReview from "./RateReview.vue";

export default {
  name: "WriteReview",
  props: {
    modelsList: Array,
    siteLanguage: String,
    getModalRef: {
      type: Function,
      default: () => ({}),
    },
  },
  mounted() {
    this.modalRef = this.getModalRef();
  },
  data() {
    return {
      selectedModel: { RNumber: "", Model: "" },
      reviewTitle: "",
      reviewText: "",
      reviewerName: "",
      reviewerEMail: "",
      feedbackRating: 0,
      designRating: "",
      qualityRating: "",
      functionalityRating: "",
      files: [],
      files: [],
      isRecommended: true,
      reviewerTerms: false,
      uploadingFiles: false,
      sendingReview: false,
      formSendSuccess: false,
      modalRef: null,
    };
  },
  components: {
    "drop-zone": DropFile,
    RateReview,
  },
  methods: {
    async updateFormFiles(files) {
      this.uploadingFiles = true;

      const toBase64 = (file) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = reject;
        });

      let formattedFileArray = [];
      for (let i = 0; i < files.length; i++) {
        let file = {};
        file.filename = files[i].name;
        file.mimetype = files[i].type;
        file.b64encodefilecontent = await toBase64(files[i]);
        formattedFileArray.push(file);
      }
      console.log(formattedFileArray);
      this.files = formattedFileArray;

      axios
        .post(
          process.env.VUE_APP_RSF_API + "/public-api/review/upload-media/json",
          { files: this.files }
        )
        .then((response) => {
          this.uploadingFiles = false;
          console.log(response);
          this.files = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    submitReview() {
      console.log("review submitted");
      let postBody = {
        RNumber: this.selectedModel.RNumber,
        reviewTitle: this.reviewTitle,
        reviewText: this.reviewText,
        reviewerName: this.reviewerName,
        reviewerEMail: this.reviewerEMail,
        feedbackRating: this.feedbackRating,
        designRating: this.designRating,
        qualityRating: this.qualityRating,
        functionalityRating: this.functionalityRating,
        files: this.files,
        isRecommended: this.isRecommended.toString(),
      };

      this.sendingReview = true;

      axios
        .post(
          process.env.VUE_APP_RSF_API + "/public-api/review/add-review/json",
          postBody
        )
        .then((response) => {
          console.log(response);
          this.sendingReview = false;
          this.formSendSuccess = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateRating(rating) {
      this.feedbackRating = rating;
    },
  },
  computed: {
    formIsFilled() {
      if (
        this.selectedModel.RNumber != "" &&
        this.reviewTitle != "" &&
        this.reviewText != "" &&
        this.reviewText.length >= 50 &&
        this.reviewerName != "" &&
        this.reviewerEMail != "" &&
        this.feedbackRating > 0 &&
        this.reviewerTerms
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>
.inactive {
  pointer-events: none;
  opacity: 0.5;
}
.size-span-text {
  font-size: 0.75rem;
}
.rsf-input,
.rsf-textarea {
  margin-bottom: 0.4rem;
}

.max-w-324 {
  max-width: 324px;
}

.mb-11 {
  margin-bottom: 11px;
}
.mb-24 {
  margin-bottom: 1.5rem;
}

@media (max-width: 832px) {
  .max-w-324 {
    max-width: none;
  }
}
</style>
