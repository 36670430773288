<template>
  <div>
    <div
      class="dropzone-container center mx-auto max-width-3 border-box mb3"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
    >
      <input
        type="file"
        multiple
        name="file"
        id="fileInput"
        class="hidden-input"
        @change="onChange"
        ref="file"
        accept=".pdf,.jpg,.jpeg,.png"
      />

      <label for="fileInput" class="file-label">
        <img
          src="https://static.redseafish.com/wp-content/uploads/2023/09/27092427/photo-icon.png"
        />
        <div
          class="open-sans-regular h5 color-13"
          style="pointer-events: none"
          v-if="isDragging"
        >
          {{ $t("reviews_upload_release") }}
        </div>
        <div
          class="open-sans-regular h5 color-13"
          style="pointer-events: none"
          v-else
        >
          {{ $t("reviews_upload_your_file") }}
        </div>
      </label>

      <div class="preview-container mt-4" v-if="files.length && !isUploading">
        <div v-for="file in files" :key="file.name" class="preview-card">
          <div>
            <img
              v-if="
                file.type == 'image/jpeg' ||
                file.type == 'image/png' ||
                file.type == 'image/tiff'
              "
              class="preview-img"
              :src="generateThumbnail(file)"
            />
            <p
              v-if="file.type == 'application/pdf'"
              class="preview-img center flex flex-column justify-center"
            >
              PDF
            </p>
            <p
              v-if="
                file.type == 'video/mp4' ||
                file.type == 'video/mov' ||
                file.type == 'video/wmv' ||
                file.type == 'video/avi'
              "
              class="center preview-img center flex flex-column justify-center"
            >
              Video
            </p>
          </div>
          <div>
            <button
              class="ml-2 preview-delete-btn"
              type="button"
              @click="remove(files.indexOf(file))"
              title="Remove file"
            >
              &times;
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="dropzone-notification-container">
      <p v-if="notificationMaxImgs" class="h5 open-sans-regular color-6">
        {{ $t("reviews_dropzone_notification_max_imgs") }}
      </p>
      <p v-if="notificationMaxVideos" class="h5 open-sans-regular color-6">
        {{ $t("reviews_dropzone_notification_max_videos") }}
      </p>
      <p v-if="notificationMaxDocs" class="h5 open-sans-regular color-6">
        {{ $t("reviews_dropzone_notification_max_docs") }}
      </p>
      <p v-if="notificationWrongFile" class="h5 open-sans-regular color-6">
        {{ $t("reviews_dropzone_notification_wrong_file") }}
      </p>
      <p
        v-if="notificationMaxImgsFileSizeOverexceeded"
        class="h5 open-sans-regular color-6"
      >
        {{ $t("reviews_dropzone_notification_max_imgs_size") }}
      </p>
      <p
        v-if="notificationMaxVideosFileSizeOverexceeded"
        class="h5 open-sans-regular color-6"
      >
        {{ $t("reviews_dropzone_notification_max_videos_size") }}
      </p>
      <p
        v-if="notificationMaxDocsFileSizeOverexceeded"
        class="h5 open-sans-regular color-6"
      >
        {{ $t("reviews_dropzone_notification_max_docs_size") }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "DropFile",
  data() {
    return {
      isDragging: false,
      files: [],
      notificationMaxImgs: false,
      notificationMaxVideos: false,
      notificationMaxDocs: false,
      notificationWrongFile: false,
      notificationMaxImgsFileSizeOverexceeded: false,
      notificationMaxVideosFileSizeOverexceeded: false,
      notificationMaxDocsFileSizeOverexceeded: false,
    };
  },
  props: {
    updateFormFiles: Function,
    isUploading: Boolean,
  },
  methods: {
    onChange() {
      this.files = [...this.$refs.file.files];
      console.log(this.files);
      this.checkMaxFileCount();
      this.updateFormFiles(this.files);
    },

    checkMaxFileCount() {
      this.notificationMaxImgs = false;
      this.notificationMaxVideos = false;
      this.notificationMaxDocs = false;
      this.notificationWrongFile = false;
      this.notificationMaxImgsFileSizeOverexceeded = false;
      this.notificationMaxVideosFileSizeOverexceeded = false;
      this.notificationMaxDocsFileSizeOverexceeded = false;

      let images = 0;
      let videos = 0;
      let documents = 0;
      let imagesFileSize = 0;
      let videosFileSize = 0;
      let docsFileSize = 0;

      const maxImagesFileSize = 30; //mb
      const maxVideosFileSize = 300; //mb
      const maxDocsFileSize = 30; //mb

      for (let i = 0; i < this.files.length; i++) {
        console.log(this.files[i]);
        console.log(this.files[i].size / (1000 * 1000));
        let validFileType = false;
        if (
          this.files[i].type == "image/jpeg" ||
          this.files[i].type == "image/png" ||
          this.files[i].type == "image/tiff"
        ) {
          images++;
          validFileType = true;
          if (images > 5) {
            this.files.splice(i, 1);
            this.notificationMaxImgs = true;
            images--;
            continue;
          }
          if (
            this.files[i].size / (1000 * 1000) + imagesFileSize <=
            maxImagesFileSize
          ) {
            //add image
            imagesFileSize =
              imagesFileSize + this.files[i].size / (1000 * 1000);
          } else {
            this.files.splice(i, 1);
            this.notificationMaxImgsFileSizeOverexceeded = true;
            images--;
            continue;
          }
        }
        // if (
        //   this.files[i].type == "video/mp4" ||
        //   this.files[i].type == "video/mov" ||
        //   this.files[i].type == "video/wmv" ||
        //   this.files[i].type == "video/avi"
        // ) {
        //   videos++;
        //   validFileType = true;
        //   if (videos > 5) {
        //     this.files.splice(i, 1);
        //     this.notificationMaxVideos = true;
        //     videos--;
        //     continue;
        //   }
        //   if (
        //     this.files[i].size / (1000 * 1000) + videosFileSize <=
        //     maxVideosFileSize
        //   ) {
        //     videosFileSize =
        //       videosFileSize + this.files[i].size / (1000 * 1000);
        //   } else {
        //     this.files.splice(i, 1);
        //     this.notificationMaxVideosFileSizeOverexceeded = true;
        //     videos--;
        //     continue;
        //   }
        // }
        // if (this.files[i].type == "application/pdf") {
        //   documents++;
        //   validFileType = true;
        //   if (documents > 5) {
        //     this.files.splice(i, 1);
        //     this.notificationMaxDocs = true;
        //     documents--;
        //     continue;
        //   }
        //   if (
        //     this.files[i].size / (1000 * 1000) + docsFileSize <=
        //     maxDocsFileSize
        //   ) {
        //     docsFileSize = docsFileSize + this.files[i].size / (1000 * 1000);
        //   } else {
        //     this.files.splice(i, 1);
        //     this.notificationMaxDocsFileSizeOverexceeded = true;
        //     documents--;
        //     continue;
        //   }
        // }
        if (!validFileType) {
          console.log("invalid files type");
          this.files.splice(i, 1);
          this.notificationWrongFile = true;
        }
      }
    },

    generateThumbnail(file) {
      let fileSrc = URL.createObjectURL(file);
      setTimeout(() => {
        URL.revokeObjectURL(fileSrc);
      }, 1000);
      return fileSrc;
    },

    makeName(name) {
      return (
        name.split(".")[0].substring(0, 3) +
        "..." +
        name.split(".")[name.split(".").length - 1]
      );
    },

    remove(i) {
      this.files.splice(i, 1);
    },

    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },

    dragleave() {
      this.isDragging = false;
    },

    drop(e) {
      e.preventDefault();
      this.$refs.file.files = e.dataTransfer.files;
      this.onChange();
      this.isDragging = false;
    },
  },
};
</script>

<style scoped>
.dropzone-container {
  padding: 3rem;
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 25px;
}
.hidden-input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
}
.file-label {
  font-size: 20px;
  display: block;
  cursor: pointer;
}
.preview-container {
  display: flex;
  margin-top: 2rem;
}
.preview-card {
  display: flex;
  margin-left: 5px;
  position: relative;
}
.preview-img {
  width: 80px;
  height: 80px;
  border-radius: 5px;
  border: 1px solid #a2a2a2;
  background-color: #a2a2a2;
  object-fit: cover;
}

.preview-delete-btn {
  position: absolute;
  z-index: 2;
  top: 2px;
  right: 2px;
  cursor: pointer;
  text-shadow: 0 0 2px white;
  font-family: "Open Sans Bold";
  font-size: var(--h4);
}

.dropzone-notification {
  position: fixed;
  top: 0;
  z-index: 99999999;
  width: 100px;
  height: 100px;
  background-color: red;
}
</style>
