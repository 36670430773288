<template>
  <router-view :siteLanguage="siteLanguage" :rNumbers="rNumbers" :title="title" :subtitle="subtitle"></router-view>
</template>

<script>

import axios from "axios";
import { EventBus } from "./event-bus";

export default {
  name: 'App',
  props: [
    "siteLanguage",
    "title",
    "subtitle",
    "rNumbers"
  ],
  data() {
    return {
      showLog: true,
    }
  },
  created() {

    if (this.siteLanguage == '') {
      this.$i18n.locale = 'en';
      this.switchLocale();
    } else {
      this.$i18n.locale = this.siteLanguage;
      this.switchLocale();
    }

    //state manager 
    EventBus.$on('eb_log', (component, msg, method) => {
      this.EBlog(component, msg, method);
    });
  },
  mounted() {

    console.log('vue app mounted')
  },
  methods: {
    EBlog(component, msg, method) {
      if (!this.showLog) { return; }
      console.log('++++++')
      if (method == 'on') {
        console.log('EventBus:on --- ' + component + ' received event: ' + msg + '.');
      }
      if (method == 'emit') {
        console.log('EventBus:emit --- ' + component + ' emitted event: ' + msg + '.')
      }
      console.log('++++++')
    },
    preSelectCountry() {
      console.log('preselect country')
      for (let i = 0; i < this.countries.length; i++) {
        if (this.countries[i].preselection_by_ip) {
          console.log('prselected by IP!')
          console.log(this.countries[i].isocode2)
          this.selectedCountry = this.countries[i];
          this.updateCountry(this.countries[i]);
          if (this.countries[i].isocode2 == 'US') {
            console.log('us - switch to imperial')
            this.measurement = 'imperial'
          } else {
            console.log('use metric system')
            this.measurement = 'metric'
          }
          return;
        }
      }

      console.log('no preselection by ip, checking site locale');

      if (this.siteLanguage == 'cn') {
        this.findCountry('CN')
        return;
      }
      else if (this.$i18n.locale == 'de') {
        this.findCountry('DE')
      }
      else if (this.$i18n.locale == 'fr') {
        this.findCountry('FR')
      }
      else if (this.$i18n.locale == 'en') {
        this.findCountry('GB')
      }
      else if (this.$i18n.locale == 'br') {
        this.findCountry('GB')
      }
      else if (this.$i18n.locale == 'ja') {
        this.findCountry('GB')
      } else {
        console.log('use fall back country')
        this.findCountry('US') // fallback US
      }
    },

    findCountry(isocode) {
      console.log('find country: ' + isocode)
      for (let a = 0; a < this.countries.length; a++) {
        if (this.countries[a].isocode2 == isocode) {
          this.selectedCountry = this.countries[a]
          this.updateCountry(this.countries[a]);
        }
      }
    },

    switchLocale() {
      var locale = this.$i18n.locale;
      const to = this.$router.resolve({ params: { lang: locale } })
      this.$router.push(to.location).catch(err => {
        if (
          err.name !== 'NavigationDuplicated' &&
          !err.message.includes('Avoided redundant navigation to current location')
        ) {
          console.log(err);
        }
      })
    },
  }
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-active {
  transition-delay: 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
}


@media only screen and (max-width: 600px) {
  .mobile-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
  }

  .mobile-flex-column {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
  }

  .mobile-flex-column-reverse {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column-reverse;
  }

  .mobile-flex-row-reverse {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row-reverse;
  }

  .mobile-col {
    float: left;
  }

  .mobile-col,
  .mobile-col-right {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .mobile-col-right {
    float: right;
  }

  .mobile-col-1 {
    width: 8.33333%;
  }

  .mobile-col-2 {
    width: 16.66667%;
  }

  .mobile-col-3 {
    width: 25%;
  }

  .mobile-col-4 {
    width: 33.33333%;
  }

  .mobile-col-5 {
    width: 41.66667%;
  }

  .mobile-col-6 {
    width: 50%;
  }

  .mobile-col-7 {
    width: 58.33333%;
  }

  .mobile-col-8 {
    width: 66.66667%;
  }

  .mobile-col-9 {
    width: 75%;
  }

  .mobile-col-10 {
    width: 83.33333%;
  }

  .mobile-col-11 {
    width: 91.66667%;
  }

  .mobile-col-12 {
    width: 100%;
  }

  .mobile-border {
    border-style: solid;
    border-width: var(--border-width);
  }

  .mobile-border-top {
    border-top-style: solid;
    border-top-width: var(--border-width);
  }

  .mobile-border-right {
    border-right-style: solid;
    border-right-width: var(--border-width);
  }

  .mobile-border-bottom {
    border-bottom-style: solid;
    border-bottom-width: var(--border-width);
  }

  .mobile-border-left {
    border-left-style: solid;
    border-left-width: var(--border-width);
  }

  .mobile-border-none {
    border: 0;
  }

  .mobile-border-top-none {
    border-top: 0;
  }

  .mobile-border-right-none {
    border-right: 0;
  }

  .mobile-border-bottom-none {
    border-bottom: 0;
  }

  .mobile-border-left-none {
    border-left: 0;
  }

  .mobile-rounded {
    border-radius: var(--border-radius);
  }

  .mobile-circle {
    border-radius: 50%;
  }

  .mobile-rounded-top {
    border-radius: var(--border-radius) var(--border-radius) 0 0;
  }

  .mobile-rounded-right {
    border-radius: 0 var(--border-radius) var(--border-radius) 0;
  }

  .mobile-rounded-bottom {
    border-radius: 0 0 var(--border-radius) var(--border-radius);
  }

  .mobile-rounded-left {
    border-radius: var(--border-radius) 0 0 var(--border-radius);
  }

  .mobile-not-rounded {
    border-radius: 0;
  }

  .mobile-inline {
    display: inline;
  }

  .mobile-block {
    display: block;
  }

  .mobile-inline-block {
    display: inline-block;
  }

  .mobile-table {
    display: table;
  }

  .mobile-table-cell {
    display: table-cell;
  }

  .mobile-overflow-hidden {
    overflow: hidden;
  }

  .mobile-overflow-scroll {
    overflow: scroll;
  }

  .mobile-overflow-auto {
    overflow: auto;
  }

  .mobile-left {
    float: left;
  }

  .mobile-right {
    float: right;
  }

  .mobile-m0 {
    margin: 0;
  }

  .mobile-mt0 {
    margin-top: 0;
  }

  .mobile-mr0 {
    margin-right: 0;
  }

  .mobile-mb0 {
    margin-bottom: 0;
  }

  .mobile-ml0,
  .mobile-mx0 {
    margin-left: 0;
  }

  .mobile-mx0 {
    margin-right: 0;
  }

  .mobile-my0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .mobile-m1 {
    margin: var(--space-1);
  }

  .mobile-mt1 {
    margin-top: var(--space-1);
  }

  .mobile-mr1 {
    margin-right: var(--space-1);
  }

  .mobile-mb1 {
    margin-bottom: var(--space-1);
  }

  .mobile-ml1,
  .mobile-mx1 {
    margin-left: var(--space-1);
  }

  .mobile-mx1 {
    margin-right: var(--space-1);
  }

  .mobile-my1 {
    margin-top: var(--space-1);
    margin-bottom: var(--space-1);
  }

  .mobile-m2 {
    margin: var(--space-2);
  }

  .mobile-mt2 {
    margin-top: var(--space-2);
  }

  .mobile-mr2 {
    margin-right: var(--space-2);
  }

  .mobile-mb2 {
    margin-bottom: var(--space-2);
  }

  .mobile-ml2,
  .mobile-mx2 {
    margin-left: var(--space-2);
  }

  .mobile-mx2 {
    margin-right: var(--space-2);
  }

  .mobile-my2 {
    margin-top: var(--space-2);
    margin-bottom: var(--space-2);
  }

  .mobile-m3 {
    margin: var(--space-3);
  }

  .mobile-mt3 {
    margin-top: var(--space-3);
  }

  .mobile-mr3 {
    margin-right: var(--space-3);
  }

  .mobile-mb3 {
    margin-bottom: var(--space-3);
  }

  .mobile-ml3,
  .mobile-mx3 {
    margin-left: var(--space-3);
  }

  .mobile-mx3 {
    margin-right: var(--space-3);
  }

  .mobile-my3 {
    margin-top: var(--space-3);
    margin-bottom: var(--space-3);
  }

  .mobile-m4 {
    margin: var(--space-4);
  }

  .mobile-mt4 {
    margin-top: var(--space-4);
  }

  .mobile-mr4 {
    margin-right: var(--space-4);
  }

  .mobile-mb4 {
    margin-bottom: var(--space-4);
  }

  .mobile-ml4,
  .mobile-mx4 {
    margin-left: var(--space-4);
  }

  .mobile-mx4 {
    margin-right: var(--space-4);
  }

  .mobile-my4 {
    margin-top: var(--space-4);
    margin-bottom: var(--space-4);
  }

  .mobile-m5 {
    margin: var(--space-5);
  }

  .mobile-mt5 {
    margin-top: var(--space-5);
  }

  .mobile-mr5 {
    margin-right: var(--space-5);
  }

  .mobile-mb5 {
    margin-bottom: var(--space-5);
  }

  .mobile-ml5,
  .mobile-mx5 {
    margin-left: var(--space-5);
  }

  .mobile-mx5 {
    margin-right: var(--space-5);
  }

  .mobile-my5 {
    margin-top: var(--space-5);
    margin-bottom: var(--space-5);
  }

  .mobile-mxn1 {
    margin-left: -var(--space-1);
    margin-right: -var(--space-1);
  }

  .mobile-mxn2 {
    margin-left: -var(--space-2);
    margin-right: -var(--space-2);
  }

  .mobile-mxn3 {
    margin-left: -var(--space-3);
    margin-right: -var(--space-3);
  }

  .mobile-mxn4 {
    margin-left: -var(--space-4);
    margin-right: -var(--space-4);
  }

  .mobile-ml-auto {
    margin-left: auto;
  }

  .mobile-mr-auto,
  .mobile-mx-auto {
    margin-right: auto;
  }

  .mobile-mx-auto {
    margin-left: auto;
  }

  .mobile-p0 {
    padding: 0;
  }

  .mobile-pt0 {
    padding-top: 0;
  }

  .mobile-pr0 {
    padding-right: 0;
  }

  .mobile-pb0 {
    padding-bottom: 0;
  }

  .mobile-pl0,
  .mobile-px0 {
    padding-left: 0;
  }

  .mobile-px0 {
    padding-right: 0;
  }

  .mobile-py0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .mobile-p1 {
    padding: var(--space-1);
  }

  .mobile-pt1 {
    padding-top: var(--space-1);
  }

  .mobile-pr1 {
    padding-right: var(--space-1);
  }

  .mobile-pb1 {
    padding-bottom: var(--space-1);
  }

  .mobile-pl1,
  .mobile-px1 {
    padding-left: var(--space-1);
  }

  .mobile-px1 {
    padding-right: var(--space-1);
  }

  .mobile-py1 {
    padding-top: var(--space-1);
    padding-bottom: var(--space-1);
  }

  .mobile-p2 {
    padding: var(--space-2);
  }

  .mobile-pt2 {
    padding-top: var(--space-2);
  }

  .mobile-pr2 {
    padding-right: var(--space-2);
  }

  .mobile-pb2 {
    padding-bottom: var(--space-2);
  }

  .mobile-pl2,
  .mobile-px2 {
    padding-left: var(--space-2);
  }

  .mobile-px2 {
    padding-right: var(--space-2);
  }

  .mobile-py2 {
    padding-top: var(--space-2);
    padding-bottom: var(--space-2);
  }

  .mobile-p3 {
    padding: var(--space-3);
  }

  .mobile-pt3 {
    padding-top: var(--space-3);
  }

  .mobile-pr3 {
    padding-right: var(--space-3);
  }

  .mobile-pb3 {
    padding-bottom: var(--space-3);
  }

  .mobile-pl3,
  .mobile-px3 {
    padding-left: var(--space-3);
  }

  .mobile-px3 {
    padding-right: var(--space-3);
  }

  .mobile-py3 {
    padding-top: var(--space-3);
    padding-bottom: var(--space-3);
  }

  .mobile-p4 {
    padding: var(--space-4);
  }

  .mobile-pt4 {
    padding-top: var(--space-4);
  }

  .mobile-pr4 {
    padding-right: var(--space-4);
  }

  .mobile-pb4 {
    padding-bottom: var(--space-4);
  }

  .mobile-pl4,
  .mobile-px4 {
    padding-left: var(--space-4);
  }

  .mobile-px4 {
    padding-right: var(--space-4);
  }

  .mobile-py4 {
    padding-top: var(--space-4);
    padding-bottom: var(--space-4);
  }

  .mobile-p5 {
    padding: var(--space-5);
  }

  .mobile-pt5 {
    padding-top: var(--space-5);
  }

  .mobile-pr5 {
    padding-right: var(--space-5);
  }

  .mobile-pb5 {
    padding-bottom: var(--space-5);
  }

  .mobile-pl5 {
    padding-left: var(--space-5);
  }

  .mobile-py5 {
    padding-top: var(--space-5);
    padding-bottom: var(--space-5);
  }

  .mobile-px5 {
    padding-left: var(--space-5);
    padding-right: var(--space-5);
  }

  .mobile-relative {
    position: relative;
  }

  .mobile-absolute {
    position: absolute;
  }

  .mobile-fixed {
    position: fixed;
  }

  .mobile-top-0 {
    top: 0;
  }

  .mobile-right-0 {
    right: 0;
  }

  .mobile-bottom-0 {
    bottom: 0;
  }

  .mobile-left-0 {
    left: 0;
  }

  .mobile-h00 {
    font-size: var(--h00);
  }

  .mobile-h0 {
    font-size: var(--h0);
  }

  .mobile-h1 {
    font-size: var(--h1);
  }

  .mobile-h2 {
    font-size: var(--h2);
  }

  .mobile-h3 {
    font-size: var(--h3);
  }

  .mobile-h4 {
    font-size: var(--h4);
  }

  .mobile-h5 {
    font-size: var(--h5);
  }

  .mobile-h6 {
    font-size: var(--h6);
  }

  .mobile-left-align {
    text-align: left;
  }

  .mobile-center {
    text-align: center;
  }

  .mobile-right-align {
    text-align: right;
  }

  .mobile-justify {
    /* text-align: justify; */
  }

  .mobile-hide {
    display: none !important;
  }

  .mobile-mt-auto {
    margin-top: auto;
  }

  .mobile-mb-auto {
    margin-bottom: auto;
  }

  .mobile-mr-auto {
    margin-right: auto;
  }

  .mobile-ml-auto {
    margin-left: auto;
  }
}
</style>
