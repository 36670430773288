<template>
  <div
    class="flex items-center gap1 mb3 pr3 relative w-fit-content"
    style="transform: scale(0.9) translateX(-8px)"
  >
    <div
      class="flex gap1 w-fit-content items-center"
      @mouseleave="
        isEditing = false;
        trueRating == 0 ? (rating = 0) : (rating = trueRating);
      "
      @click="
        trueRating = rating;
        isEditing = false;
      "
      @mouseover="isEditing = true"
      @touchmove="handleTouch"
    >
      <div class="relative w-fit-content" style="height: 42px; width: 44px">
        <div class="star-overlay-full" @mouseover="rating = 1"></div>
        <!-- full -->
        <svg
          :class="`${
            trueRating > 0 && !isEditing ? 'svg-color-5' : 'svg-color-5-l'
          }`"
          v-if="rating >= 1"
          width="44"
          height="42"
          viewBox="0 0 44 42"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22.1724 0.0861206L28.7354 13.4331C28.8828 13.7327 29.1676 13.9404 29.497 13.9884L44.1724 16.1287L33.5531 26.5179C33.3147 26.7511 33.206 27.0871 33.2623 27.4164L35.7691 42.0861L22.6431 35.1601C22.3484 35.0045 21.9964 35.0045 21.7017 35.1601L8.57564 42.0861L11.0825 27.4164C11.1387 27.0871 11.03 26.7511 10.7916 26.5179L0.172394 16.1287L14.8478 13.9884C15.1773 13.9404 15.462 13.7327 15.6094 13.4331L22.1724 0.0861206Z"
            fill="#FFC700"
          />
        </svg>
        <!-- half -->
        <svg
          :class="`${
            trueRating > 0 && !isEditing ? 'svg-color-5' : 'svg-color-5-l'
          }`"
          v-if="rating == 0.5"
          width="44"
          height="42"
          viewBox="0 0 44 42"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M22.1747 0.0861594L22.1747 0.0861206L15.6117 13.4331C15.4643 13.7327 15.1796 13.9404 14.8501 13.9884L0.174698 16.1287L10.7939 26.5179C11.0323 26.7511 11.141 27.0871 11.0848 27.4164L8.57795 42.0861L21.704 35.1601C21.8514 35.0823 22.013 35.0434 22.1747 35.0434V0.0861594Z"
            fill="#FFC700"
          />
        </svg>
        <!-- empty -->
        <svg
          class="empty-star"
          width="44"
          height="42"
          viewBox="0 0 44 42"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22.6134 1.21924L28.7277 13.6537L28.7277 13.6538C28.9475 14.1006 29.3728 14.4113 29.8659 14.4832L43.5417 16.4777L33.6445 26.1604C33.6445 26.1604 33.6445 26.1605 33.6445 26.1605C33.2884 26.5087 33.1266 27.0099 33.2104 27.5006L33.2104 27.5006L35.5464 41.1706L23.3174 34.7178C22.8767 34.4853 22.35 34.4852 21.9093 34.7179L9.68032 41.1706L12.0163 27.5006L12.0163 27.5006C12.1001 27.0099 11.9383 26.5087 11.5822 26.1605C11.5822 26.1605 11.5822 26.1604 11.5822 26.1604L1.68507 16.4777L15.3609 14.4832C15.854 14.4113 16.2792 14.1006 16.499 13.6538L16.499 13.6537L22.6134 1.21924Z"
            stroke="#FF9900"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div class="relative w-fit-content" style="height: 42px; width: 44px">
        <div class="star-overlay-left" @mouseover="rating = 2"></div>
        <div class="star-overlay-right" @mouseover="rating = 2"></div>
        <!-- full -->
        <svg
          :class="`${
            trueRating > 0 && !isEditing ? 'svg-color-5' : 'svg-color-5-l'
          }`"
          v-if="rating >= 2"
          width="44"
          height="42"
          viewBox="0 0 44 42"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22.1724 0.0861206L28.7354 13.4331C28.8828 13.7327 29.1676 13.9404 29.497 13.9884L44.1724 16.1287L33.5531 26.5179C33.3147 26.7511 33.206 27.0871 33.2623 27.4164L35.7691 42.0861L22.6431 35.1601C22.3484 35.0045 21.9964 35.0045 21.7017 35.1601L8.57564 42.0861L11.0825 27.4164C11.1387 27.0871 11.03 26.7511 10.7916 26.5179L0.172394 16.1287L14.8478 13.9884C15.1773 13.9404 15.462 13.7327 15.6094 13.4331L22.1724 0.0861206Z"
            fill="#FFC700"
          />
        </svg>
        <!-- half -->
        <svg
          :class="`${
            trueRating > 0 && !isEditing ? 'svg-color-5' : 'svg-color-5-l'
          }`"
          v-if="rating == 1.5"
          width="44"
          height="42"
          viewBox="0 0 44 42"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M22.1747 0.0861594L22.1747 0.0861206L15.6117 13.4331C15.4643 13.7327 15.1796 13.9404 14.8501 13.9884L0.174698 16.1287L10.7939 26.5179C11.0323 26.7511 11.141 27.0871 11.0848 27.4164L8.57795 42.0861L21.704 35.1601C21.8514 35.0823 22.013 35.0434 22.1747 35.0434V0.0861594Z"
            fill="#FFC700"
          />
        </svg>
        <!-- empty -->
        <svg
          class="empty-star"
          width="44"
          height="42"
          viewBox="0 0 44 42"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22.6134 1.21924L28.7277 13.6537L28.7277 13.6538C28.9475 14.1006 29.3728 14.4113 29.8659 14.4832L43.5417 16.4777L33.6445 26.1604C33.6445 26.1604 33.6445 26.1605 33.6445 26.1605C33.2884 26.5087 33.1266 27.0099 33.2104 27.5006L33.2104 27.5006L35.5464 41.1706L23.3174 34.7178C22.8767 34.4853 22.35 34.4852 21.9093 34.7179L9.68032 41.1706L12.0163 27.5006L12.0163 27.5006C12.1001 27.0099 11.9383 26.5087 11.5822 26.1605C11.5822 26.1605 11.5822 26.1604 11.5822 26.1604L1.68507 16.4777L15.3609 14.4832C15.854 14.4113 16.2792 14.1006 16.499 13.6538L16.499 13.6537L22.6134 1.21924Z"
            stroke="#FF9900"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div class="relative w-fit-content" style="height: 42px; width: 44px">
        <div class="star-overlay-left" @mouseover="rating = 3"></div>
        <div class="star-overlay-right" @mouseover="rating = 3"></div>
        <!-- full -->
        <svg
          :class="`${
            trueRating > 0 && !isEditing ? 'svg-color-5' : 'svg-color-5-l'
          }`"
          v-if="rating >= 3"
          width="44"
          height="42"
          viewBox="0 0 44 42"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22.1724 0.0861206L28.7354 13.4331C28.8828 13.7327 29.1676 13.9404 29.497 13.9884L44.1724 16.1287L33.5531 26.5179C33.3147 26.7511 33.206 27.0871 33.2623 27.4164L35.7691 42.0861L22.6431 35.1601C22.3484 35.0045 21.9964 35.0045 21.7017 35.1601L8.57564 42.0861L11.0825 27.4164C11.1387 27.0871 11.03 26.7511 10.7916 26.5179L0.172394 16.1287L14.8478 13.9884C15.1773 13.9404 15.462 13.7327 15.6094 13.4331L22.1724 0.0861206Z"
            fill="#FFC700"
          />
        </svg>
        <!-- half -->
        <svg
          :class="`${
            trueRating > 0 && !isEditing ? 'svg-color-5' : 'svg-color-5-l'
          }`"
          v-if="rating == 2.5"
          width="44"
          height="42"
          viewBox="0 0 44 42"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M22.1747 0.0861594L22.1747 0.0861206L15.6117 13.4331C15.4643 13.7327 15.1796 13.9404 14.8501 13.9884L0.174698 16.1287L10.7939 26.5179C11.0323 26.7511 11.141 27.0871 11.0848 27.4164L8.57795 42.0861L21.704 35.1601C21.8514 35.0823 22.013 35.0434 22.1747 35.0434V0.0861594Z"
            fill="#FFC700"
          />
        </svg>
        <!-- empty -->
        <svg
          class="empty-star"
          width="44"
          height="42"
          viewBox="0 0 44 42"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22.6134 1.21924L28.7277 13.6537L28.7277 13.6538C28.9475 14.1006 29.3728 14.4113 29.8659 14.4832L43.5417 16.4777L33.6445 26.1604C33.6445 26.1604 33.6445 26.1605 33.6445 26.1605C33.2884 26.5087 33.1266 27.0099 33.2104 27.5006L33.2104 27.5006L35.5464 41.1706L23.3174 34.7178C22.8767 34.4853 22.35 34.4852 21.9093 34.7179L9.68032 41.1706L12.0163 27.5006L12.0163 27.5006C12.1001 27.0099 11.9383 26.5087 11.5822 26.1605C11.5822 26.1605 11.5822 26.1604 11.5822 26.1604L1.68507 16.4777L15.3609 14.4832C15.854 14.4113 16.2792 14.1006 16.499 13.6538L16.499 13.6537L22.6134 1.21924Z"
            stroke="#FF9900"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div class="relative w-fit-content" style="height: 42px; width: 44px">
        <div class="star-overlay-left" @mouseover="rating = 4"></div>
        <div class="star-overlay-right" @mouseover="rating = 4"></div>
        <!-- full -->
        <svg
          :class="`${
            trueRating > 0 && !isEditing ? 'svg-color-5' : 'svg-color-5-l'
          }`"
          v-if="rating >= 4"
          width="44"
          height="42"
          viewBox="0 0 44 42"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22.1724 0.0861206L28.7354 13.4331C28.8828 13.7327 29.1676 13.9404 29.497 13.9884L44.1724 16.1287L33.5531 26.5179C33.3147 26.7511 33.206 27.0871 33.2623 27.4164L35.7691 42.0861L22.6431 35.1601C22.3484 35.0045 21.9964 35.0045 21.7017 35.1601L8.57564 42.0861L11.0825 27.4164C11.1387 27.0871 11.03 26.7511 10.7916 26.5179L0.172394 16.1287L14.8478 13.9884C15.1773 13.9404 15.462 13.7327 15.6094 13.4331L22.1724 0.0861206Z"
            fill="#FFC700"
          />
        </svg>
        <!-- half -->
        <svg
          :class="`${
            trueRating > 0 && !isEditing ? 'svg-color-5' : 'svg-color-5-l'
          }`"
          v-if="rating == 3.5"
          width="44"
          height="42"
          viewBox="0 0 44 42"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M22.1747 0.0861594L22.1747 0.0861206L15.6117 13.4331C15.4643 13.7327 15.1796 13.9404 14.8501 13.9884L0.174698 16.1287L10.7939 26.5179C11.0323 26.7511 11.141 27.0871 11.0848 27.4164L8.57795 42.0861L21.704 35.1601C21.8514 35.0823 22.013 35.0434 22.1747 35.0434V0.0861594Z"
            fill="#FFC700"
          />
        </svg>
        <!-- empty -->
        <svg
          class="empty-star"
          width="44"
          height="42"
          viewBox="0 0 44 42"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22.6134 1.21924L28.7277 13.6537L28.7277 13.6538C28.9475 14.1006 29.3728 14.4113 29.8659 14.4832L43.5417 16.4777L33.6445 26.1604C33.6445 26.1604 33.6445 26.1605 33.6445 26.1605C33.2884 26.5087 33.1266 27.0099 33.2104 27.5006L33.2104 27.5006L35.5464 41.1706L23.3174 34.7178C22.8767 34.4853 22.35 34.4852 21.9093 34.7179L9.68032 41.1706L12.0163 27.5006L12.0163 27.5006C12.1001 27.0099 11.9383 26.5087 11.5822 26.1605C11.5822 26.1605 11.5822 26.1604 11.5822 26.1604L1.68507 16.4777L15.3609 14.4832C15.854 14.4113 16.2792 14.1006 16.499 13.6538L16.499 13.6537L22.6134 1.21924Z"
            stroke="#FF9900"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div class="relative w-fit-content" style="height: 42px; width: 44px">
        <div class="star-overlay-left" @mouseover="rating = 5"></div>
        <div class="star-overlay-right" @mouseover="rating = 5"></div>
        <!-- full -->
        <svg
          :class="`${
            trueRating > 0 && !isEditing ? 'svg-color-5' : 'svg-color-5-l'
          }`"
          v-if="rating >= 5"
          width="44"
          height="42"
          viewBox="0 0 44 42"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22.1724 0.0861206L28.7354 13.4331C28.8828 13.7327 29.1676 13.9404 29.497 13.9884L44.1724 16.1287L33.5531 26.5179C33.3147 26.7511 33.206 27.0871 33.2623 27.4164L35.7691 42.0861L22.6431 35.1601C22.3484 35.0045 21.9964 35.0045 21.7017 35.1601L8.57564 42.0861L11.0825 27.4164C11.1387 27.0871 11.03 26.7511 10.7916 26.5179L0.172394 16.1287L14.8478 13.9884C15.1773 13.9404 15.462 13.7327 15.6094 13.4331L22.1724 0.0861206Z"
            fill="#FFC700"
          />
        </svg>
        <!-- half -->
        <svg
          :class="`${
            trueRating > 0 && !isEditing ? 'svg-color-5' : 'svg-color-5-l'
          }`"
          v-if="rating == 4.5"
          width="44"
          height="42"
          viewBox="0 0 44 42"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M22.1747 0.0861594L22.1747 0.0861206L15.6117 13.4331C15.4643 13.7327 15.1796 13.9404 14.8501 13.9884L0.174698 16.1287L10.7939 26.5179C11.0323 26.7511 11.141 27.0871 11.0848 27.4164L8.57795 42.0861L21.704 35.1601C21.8514 35.0823 22.013 35.0434 22.1747 35.0434V0.0861594Z"
            fill="#FFC700"
          />
        </svg>
        <!-- empty -->
        <svg
          class="empty-star"
          width="44"
          height="42"
          viewBox="0 0 44 42"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22.6134 1.21924L28.7277 13.6537L28.7277 13.6538C28.9475 14.1006 29.3728 14.4113 29.8659 14.4832L43.5417 16.4777L33.6445 26.1604C33.6445 26.1604 33.6445 26.1605 33.6445 26.1605C33.2884 26.5087 33.1266 27.0099 33.2104 27.5006L33.2104 27.5006L35.5464 41.1706L23.3174 34.7178C22.8767 34.4853 22.35 34.4852 21.9093 34.7179L9.68032 41.1706L12.0163 27.5006L12.0163 27.5006C12.1001 27.0099 11.9383 26.5087 11.5822 26.1605C11.5822 26.1605 11.5822 26.1604 11.5822 26.1604L1.68507 16.4777L15.3609 14.4832C15.854 14.4113 16.2792 14.1006 16.499 13.6538L16.499 13.6537L22.6134 1.21924Z"
            stroke="#FF9900"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
    <svg
      :class="`reset-rating svg-color-53 pointer ${
        trueRating > 0 ? 'op100' : 'op0 pointer-events-none'
      }`"
      @click="
        trueRating = 0;
        rating = 0;
      "
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1922_48204)">
        <rect
          width="19.6867"
          height="2"
          rx="1"
          transform="matrix(0.707107 0.707107 0.707107 -0.707107 0.15625 1.58594)"
          fill="black"
        />
        <rect
          x="15.4902"
          y="1.58594"
          width="19.6867"
          height="2"
          rx="1"
          transform="rotate(135 15.4902 1.58594)"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_1922_48204">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="matrix(-1 0 0 1 16 0)"
          />
        </clipPath>
      </defs>
    </svg>
  </div>
</template>

<script>
export default {
  name: "RateReview",
  props: {
    updateRating: Function,
  },
  data() {
    return {
      rating: 0,
      ratingLocked: false,
      trueRating: 0,
      isEditing: false,
    };
  },
  watch: {
    trueRating(newVal, oldVal) {
      if (newVal != oldVal) {
        this.updateRating(newVal);
      }
    },
  },
  methods: {
    handleTouch(e) {
      console.log(e);
    },
  },
};
</script>

<style scoped>
.star-overlay-left,
.star-overlay-right {
  position: absolute;
  z-index: 3;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 50%;
}

.star-overlay-full {
  position: absolute;
  z-index: 3;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
}
.star-overlay-left {
  left: 0;
}
.star-overlay-right {
  right: 0;
}
.empty-star {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
}
.reset-rating {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}

svg.svg-color-5-l *[fill] {
  fill: #ffdf6e;
}

svg.svg-color-15-l *[stroke] {
  stroke: #ffdf6e;
}
</style>
