var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"dropzone-container center mx-auto max-width-3 border-box mb3",on:{"dragover":_vm.dragover,"dragleave":_vm.dragleave,"drop":_vm.drop}},[_c('input',{ref:"file",staticClass:"hidden-input",attrs:{"type":"file","multiple":"","name":"file","id":"fileInput","accept":".pdf,.jpg,.jpeg,.png"},on:{"change":_vm.onChange}}),_c('label',{staticClass:"file-label",attrs:{"for":"fileInput"}},[_c('img',{attrs:{"src":"https://static.redseafish.com/wp-content/uploads/2023/09/27092427/photo-icon.png"}}),(_vm.isDragging)?_c('div',{staticClass:"open-sans-regular h5 color-13",staticStyle:{"pointer-events":"none"}},[_vm._v(" "+_vm._s(_vm.$t("reviews_upload_release"))+" ")]):_c('div',{staticClass:"open-sans-regular h5 color-13",staticStyle:{"pointer-events":"none"}},[_vm._v(" "+_vm._s(_vm.$t("reviews_upload_your_file"))+" ")])]),(_vm.files.length && !_vm.isUploading)?_c('div',{staticClass:"preview-container mt-4"},_vm._l((_vm.files),function(file){return _c('div',{key:file.name,staticClass:"preview-card"},[_c('div',[(
              file.type == 'image/jpeg' ||
              file.type == 'image/png' ||
              file.type == 'image/tiff'
            )?_c('img',{staticClass:"preview-img",attrs:{"src":_vm.generateThumbnail(file)}}):_vm._e(),(file.type == 'application/pdf')?_c('p',{staticClass:"preview-img center flex flex-column justify-center"},[_vm._v(" PDF ")]):_vm._e(),(
              file.type == 'video/mp4' ||
              file.type == 'video/mov' ||
              file.type == 'video/wmv' ||
              file.type == 'video/avi'
            )?_c('p',{staticClass:"center preview-img center flex flex-column justify-center"},[_vm._v(" Video ")]):_vm._e()]),_c('div',[_c('button',{staticClass:"ml-2 preview-delete-btn",attrs:{"type":"button","title":"Remove file"},on:{"click":function($event){_vm.remove(_vm.files.indexOf(file))}}},[_vm._v(" × ")])])])}),0):_vm._e()]),_c('div',{staticClass:"dropzone-notification-container"},[(_vm.notificationMaxImgs)?_c('p',{staticClass:"h5 open-sans-regular color-6"},[_vm._v(" "+_vm._s(_vm.$t("reviews_dropzone_notification_max_imgs"))+" ")]):_vm._e(),(_vm.notificationMaxVideos)?_c('p',{staticClass:"h5 open-sans-regular color-6"},[_vm._v(" "+_vm._s(_vm.$t("reviews_dropzone_notification_max_videos"))+" ")]):_vm._e(),(_vm.notificationMaxDocs)?_c('p',{staticClass:"h5 open-sans-regular color-6"},[_vm._v(" "+_vm._s(_vm.$t("reviews_dropzone_notification_max_docs"))+" ")]):_vm._e(),(_vm.notificationWrongFile)?_c('p',{staticClass:"h5 open-sans-regular color-6"},[_vm._v(" "+_vm._s(_vm.$t("reviews_dropzone_notification_wrong_file"))+" ")]):_vm._e(),(_vm.notificationMaxImgsFileSizeOverexceeded)?_c('p',{staticClass:"h5 open-sans-regular color-6"},[_vm._v(" "+_vm._s(_vm.$t("reviews_dropzone_notification_max_imgs_size"))+" ")]):_vm._e(),(_vm.notificationMaxVideosFileSizeOverexceeded)?_c('p',{staticClass:"h5 open-sans-regular color-6"},[_vm._v(" "+_vm._s(_vm.$t("reviews_dropzone_notification_max_videos_size"))+" ")]):_vm._e(),(_vm.notificationMaxDocsFileSizeOverexceeded)?_c('p',{staticClass:"h5 open-sans-regular color-6"},[_vm._v(" "+_vm._s(_vm.$t("reviews_dropzone_notification_max_docs_size"))+" ")]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }