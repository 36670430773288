<template>
  <div @click="close()" :class="`rsf-modal stb_modal p2 border-box ${showModal ? 'stb_modal_active' : 'pointer-events-none'}`">
    <div @click.stop="" :class="`rsf-modal-inner relative rounded-border col-12 bg-color-white border-box-4 mx-auto carousel-min-h ${showModal ? '' : 'pointer-events-none'}`" style="max-width: 972px;" @mouseenter="showInfo = false" @mouseleave="showInfo = true">
      <div @click="close()" class="rsf-modal-close-btn relative bg-color-white pointer carousel-modal-close">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_1922_48204)">
          <rect width="19.6867" height="2" rx="1" transform="matrix(0.707107 0.707107 0.707107 -0.707107 0.15625 1.58594)" fill="black"/>
          <rect x="15.4902" y="1.58594" width="19.6867" height="2" rx="1" transform="rotate(135 15.4902 1.58594)" fill="black"/>
          </g>
          <defs>
          <clipPath id="clip0_1922_48204">
          <rect width="16" height="16" fill="white" transform="matrix(-1 0 0 1 16 0)"/>
          </clipPath>
          </defs>
        </svg>
      </div>

      <div v-if="slides.length > 0" :class="`gradient-overlay bg-gradient ${slides[activeSlide].isVideo ? 'hide-reviewer-info' : showInfo ? '' : 'hide-reviewer-info'}`">
      </div>

      <div v-if="slides.length > 0" :class="`p3 mx-auto reviewer-info col-12 border-box ${slides[activeSlide].isVideo ? 'hide-reviewer-info' : showInfo ? '' : 'hide-reviewer-info'}`">
        <p class="helvetica-bold color-white lg-h2 h6 center lg-mb1">{{ reviewerName }}, {{ reviewrModel }}</p>
        <p class="open-sans-regular color-white lg-h5 f-12px center">{{ reviewTitle }}</p>
      </div>

      <div class="review-carousel-arrow review-carousel-left-arrow pointer" @click="advanceSlide(-1)">
        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.28564 11.1428L1.14279 5.99997L6.28565 0.857112" stroke="#0E1317" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>

      <div class="review-carousel-arrow review-carousel-right-arrow pointer" @click="advanceSlide(1)">
        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.28564 11.1428L1.14279 5.99997L6.28565 0.857112" stroke="#0E1317" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>

      <img v-if="!slide.isVideo && slides.length > 0" v-for="(slide, index) in slides" :id="'slide-media-' + index" :key="'img-' + index" :class="`review-slider-img ${index == activeSlide && showModal ? 'active' : ''} `" :src="slide.large != '' ? slide.large : slide.medium != '' ? slide.medium : slide.small != '' ? slide.small : ''">
      <video v-if="slide.isVideo && slides.length > 0" v-for="(slide, index) in slides" :id="'slide-media-' + index" :key="'img-' + index" :class="`review-slider-img pointer ${index == activeSlide && showModal ? 'active' : ''}`" :src="slide.url" controls></video>

      <div class="flex slider-nav-items items-center">
        <div 
          v-for="(img, index) in slides"
          :key="index"
          :class="`slider-item py2 pointer ${index == activeSlide ? 'active' : ''}`" 
          @click="activeSlide = index"
        >
          <div :class="`slider-item-inner ${index == activeSlide ? 'bg-color-1' : 'bg-color-white'}`" ></div>
        </div>
      </div>

      
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/event-bus'

export default {
  name: 'carousel-modal',
  props: {
  },
  data(){
    return {
      showModal: false,
      activeSlide: 0,
      slides: [],
      reviewerName: '',
      reviewrModel: '',
      reviewTitle: '',
      showInfo: true,
    }
  },
  methods: {
    open(){this.showModal = true},
    close(){this.showModal = false;},
    advanceSlide(dir){
      if(dir == -1){
        this.activeSlide = this.activeSlide-1;
      }
      if(dir == 1){
        this.activeSlide = this.activeSlide+1;
      }
      if(this.activeSlide > this.slides.length-1){
        this.activeSlide = 0;
      }
      if(this.activeSlide < 0){
        this.activeSlide = this.slides.length-1;
      }
      this.pauseVideos();
    },
    pauseVideos(){
      for(let i = 0; i < this.slides.length; i++){
        if(this.slides[i].isVideo){
          document.getElementById('slide-media-' + i).pause();
        }
      }
    }

  },
  created(){
    EventBus.$on('send_carousel_modal_data', (payload) => {
      this.slides = payload.slides;
      this.reviewerName = payload.reviewerName;
      this.reviewrModel = payload.reviewrModel;
      this.reviewTitle = payload.reviewTitle;
      this.activeSlide = payload.activeSlide;
    })
  },
  watch: {
    showModal(newValue, oldValue){
        if(newValue == false){
          this.pauseVideos();
        }
    }
  }
}
</script>
<style scoped>
  .rsf-modal::-webkit-scrollbar {
    display: none;
  }
  .rsf-modal {
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: auto;
  }

  .rsf-modal-close-btn{
    margin-left: auto;
    width:fit-content;
  }


  .carousel-modal-close{
    position: absolute; 
    top: -4rem; 
    left: 50%; 
    transform: translateX(-50%);
    width: 3rem;
    aspect-ratio: 1 /1;
    border-radius: 999px;
  }

  .f-12px{
    font-size: 12px;
  }

  .carousel-modal-close svg{
    position: absolute; 
    left: 50%; 
    top: 50%; 
    transform: translate(-50%, -50%);
  }

  .gradient-overlay{
    position: absolute; 
    top: 0; 
    left: 0; 
    height: 100%; 
    width: 100%; 
    border-radius: 20px;
    z-index: 1;
    transition: .3s ease; 
    transition-delay: .3s; 
    opacity: 1; 
    pointer-events: none;
  }

  .slider-nav-items{
    gap: .5rem;
    position: absolute; 
    bottom: -1.25rem; 
    left: 50%; 
    transform: translate(-50%);
    height: 5px;
  }
  .slider-item{
    height: 3px;
    width: 24px;
  }

  .slider-item-inner{
    border-radius: 99px;
    height: 100%; 
    width: 100%;
  }

  .slider-item.active{
    height: 5px;
  }

  .reviewer-info{
    position: absolute; 
    bottom: 0;
    z-index: 2;
  }

  .review-slider-img{
    position: absolute; 
    z-index: 0;
    top: 0; 
    left: 0; 
    width: 100%; 
    height: 100%; 
    object-fit: contain;
    border-radius: 20px;
    pointer-events: none;
    opacity: 0; 
    pointer-events: none;
    background-color: black;
  }

  .review-slider-img.active{
    pointer-events: all;
    opacity: 1; 
  }

  .review-carousel-arrow{
    position: absolute; 
    top: 50%; 
    transform: translateY(-50%);
    width: 36px;
    aspect-ratio: 1 / 1; 
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 999px;
    z-index: 3;
  }

  .review-carousel-left-arrow{
    left: 1rem;
  }

  .review-carousel-right-arrow{
    right: 1rem;
    transform: translateY(-50%) rotate(180deg);
  }

  .review-carousel-arrow svg{
    position: absolute; 
    top: 50%;
    left: 50%; 
    transform: translate(-50%, -50%)
  }

  .reviewer-info{
    transition: .3s ease; 
    transition-delay: .3s;
    transform: translateY(0);
    opacity: 1; 
    pointer-events: none;
  }

  .hide-reviewer-info{
    opacity: 0; 
  }

  .carousel-min-h{
    min-height: 50vh; 
  }

  @media(min-width: 1024px){
    .f-12px{
    font-size: inherit;
  }
  .carousel-min-h{
    min-height: 70vh; 
  }
  }

</style>