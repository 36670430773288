import ReviewsApp from './components/ReviewsApp.vue'

export default [
    {
        path: '/:lang', 
        component: ReviewsApp,
        name: 'app'
    },
    {
        path: '/:lang/model=:model', 
        component: ReviewsApp,
        name: 'app-model'
    },
    {
        path: "*",
        redirect: "/en",
        component: ReviewsApp,
    }
]