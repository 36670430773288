<template>
  <div>
    
    <img v-if="loadingReviews" class="block center mx-auto my3" src="https://static.redseafish.com/wp-content/uploads/2023/09/07130304/spinner2.gif"/>
    <div v-if="!loadingReviews && reviews.length > 0" >
      <div class="flex justify-center items-center mb3">
        <!-- TODO: Count number -->
        <p class="open-sans-bold h3">{{$t('reviews_overall_rating')}} ({{ reviewCount }})</p>
      </div>
      <div class="mx-auto col-12 center flex justify-center rsf-product-rating md-mb2 items-center mb1">
        <div class="center">
          <!-- TODO: Calc Avg rating -->
          <p class="open-sans-bold md-h1 mt1 md-mr2 mr1 md-h1 h2">{{rating}}</p>
        </div>
        <div class="flex justify-center" style="gap: 5px; transform:scale(1)">
          <Star :fill="splitRating[0]" clipId="1" :transitionDelay="0" />
          <Star :fill="splitRating[1]" clipId="2" :transitionDelay="0.1" />
          <Star :fill="splitRating[2]" clipId="3" :transitionDelay="0.2" />
          <Star :fill="splitRating[3]" clipId="4" :transitionDelay="0.3" />
          <Star :fill="splitRating[4]" clipId="5" :transitionDelay="0.4" />
        </div>

      </div>
      <div class="flex justify-center items-center mb2">
        <!-- TODO calc recommendation percentage -->
        <p class="open-sans-regular color-14 h5"><b class="h2 color-4 open-sans-bold">{{recommendationsPercent}}%</b>  buyers recommend this product</p>
      </div>
    </div>

  </div>
</template>

<script>
// import axios from 'axios'
// import { EventBus } from '@/event-bus'
import Star from './Star'

export default {
  name: 'Rating',
  props: {
    rating: Number,
    reviewCount: Number,
    reviews: Array,
    loadingReviews: Boolean
  },
  components: {
    Star
  },
  data() {
    return {
      star1Fill: 0,
      star2Fill: 0,
      star3Fill: 0,
      star4Fill: 0,
      star5Fill: 0,
    }
  },
  computed: {
    splitRating() {
      let arr = []
      let totalRating = this.rating * 20

      //split / 20 * 100 = rest

      let v1 = totalRating - 20
      if (v1 >= 0) {
        arr[0] = 100
      } else {
        arr[0] = totalRating / 20 * 100;
        return [arr[0], 0, 0, 0, 0]
      }
      let v2 = v1 - 20
      if (v2 >= 0) {
        arr[1] = 100
      } else {
        arr[1] = v1 / 20 * 100;
        return [arr[0], arr[1], 0, 0, 0]
      }
      let v3 = v2 - 20
      if (v3 >= 0) {
        arr[2] = 100
      } else {
        arr[2] = v2 / 20 * 100;
        return [arr[0], arr[1], arr[2], 0, 0]
      }
      let v4 = v3 - 20
      if (v4 >= 0) {
        arr[3] = 100
      } else {
        arr[3] = v3 / 20 * 100;
        return [arr[0], arr[1], arr[2], arr[3], 0]
      }
      let v5 = v4 - 20
      if (v5 >= 0) {
        arr[4] = 100
      } else {
        arr[4] = v4 / 20 * 100;
        return [arr[0], arr[1], arr[2], arr[3], arr[4]]
      }

      return arr
    },
    recommendationsPercent(){
    let helpfulCount = 0; 
    for(let i = 0; i < this.reviews.length; i++){
      if(this.reviews[i].isRecommended){
        helpfulCount++;
      }
    }
    return Math.round((helpfulCount / this.reviewCount)*100);
  },
  },
  created() {

  },
  methods: {

  }
}
</script>

<style scoped></style>
