<template>
  <div class="star-wrapper relative">
    <div class="star-clip" :style="`clip-path: url(#rsf-star-clip-${clipId})`">
      <div class="star-inner bg-color-5" :style="`width: ${fill > 0 ? fill : 0}%; transition-delay: ${transitionDelay}s`"></div>
    </div>
    <svg width="46" height="44" viewBox="0 0 46 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M23.0718 1.12976L29.5605 14.2774C29.7062 14.5725 29.9878 14.7771 30.3135 14.8244L44.8227 16.9327L34.3237 27.1667C34.088 27.3964 33.9805 27.7274 34.0361 28.0518L36.5146 42.5024L23.5372 35.6798C23.2458 35.5266 22.8978 35.5266 22.6065 35.6798L9.62902 42.5024L12.1075 28.0518C12.1631 27.7274 12.0556 27.3964 11.8199 27.1667L1.32092 16.9327L15.8302 14.8244C16.1559 14.7771 16.4374 14.5725 16.5831 14.2774L23.0718 1.12976Z" stroke="#FF9900" :stroke-width="strokeWidth ? strokeWidth : 1" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg width="46" height="44" viewBox="0 0 46 44" fill="none">
      <defs>
        <clipPath :id="'rsf-star-clip-' + clipId">
          <path d="M23.0718 1.12976L29.5605 14.2774C29.7062 14.5725 29.9878 14.7771 30.3135 14.8244L44.8227 16.9327L34.3237 27.1667C34.088 27.3964 33.9805 27.7274 34.0361 28.0518L36.5146 42.5024L23.5372 35.6798C23.2458 35.5266 22.8978 35.5266 22.6065 35.6798L9.62902 42.5024L12.1075 28.0518C12.1631 27.7274 12.0556 27.3964 11.8199 27.1667L1.32092 16.9327L15.8302 14.8244C16.1559 14.7771 16.4374 14.5725 16.5831 14.2774L23.0718 1.12976Z" stroke="#FF9900" stroke-linecap="round" stroke-linejoin="round"/>
        </clipPath>
      </defs>
    </svg>
  </div>
</template>

<script>
// import axios from 'axios'
// import { EventBus } from '@/event-bus'

export default {
  name: 'Star',
  props: {
    fill: Number,
    clipId: String,
    transitionDelay: Number,
    strokeWidth: Number
  },
  data() {
    return {
    }
  },
  created() {

  },
  methods: {

  }
}
</script>

<style scoped>
.star-wrapper{
  width: 46px;
  height: 44px;
}

.star-clip{
  width: 46px;
  height: 44px;
  background: transparent; 
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.star-outline{
    position: absolute; 
    top: 0;
    left: 0;
}

.star-inner{
    height: 100%;
    transition: .1s ease;
}
</style>
