<template>
  <div @click="close()" :class="`rsf-modal stb_modal lg-p2 border-box ${showModal ? 'stb_modal_active' : ''}`">
    <div @click.stop="" class="rsf-modal-inner relative rounded-border p2 col-12 bg-color-white border-box max-width-3 mx-auto">
      <div @click="close()" class="rsf-modal-close-btn relative bg-color-white pointer lg-pt2 lg-pr2">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_1922_48204)">
          <rect width="19.6867" height="2" rx="1" transform="matrix(0.707107 0.707107 0.707107 -0.707107 0.15625 1.58594)" fill="black"/>
          <rect x="15.4902" y="1.58594" width="19.6867" height="2" rx="1" transform="rotate(135 15.4902 1.58594)" fill="black"/>
          </g>
          <defs>
          <clipPath id="clip0_1922_48204">
          <rect width="16" height="16" fill="white" transform="matrix(-1 0 0 1 16 0)"/>
          </clipPath>
          </defs>
        </svg>

      </div>

      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'rsf-modal',
  props: {
  },
  data(){
    return {
      showModal: false   
    }
  },
  methods: {
    open(){this.showModal = true},
    close(){this.showModal = false}

  }
}
</script>
<style scoped>
  .rsf-modal::-webkit-scrollbar {
    display: none;
  }
  .rsf-modal {
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: auto;
  }

  @media(max-width: 1024px){
    .rsf-modal{
      padding-top: 2.5rem;
    }
  }

  .rsf-modal-close-btn{
    margin-left: auto;
    width:fit-content;
  }

  .rsf-modal-close-btn svg{
  }

  @media(max-width: 1024px){
    .rsf-modal-inner{
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
    .rsf-modal{
      place-items:end;
    }
  }

</style>