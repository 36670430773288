<template>
  <div
    class="col-12 bg-color-white max-width-5 mx-auto md-flex mb2 border rounded-card-review mobile-flex-column review-item mobile-col-12 border-box"
  >
    <div
      class="col-12 flex flex-column justify-between md-mb0 mb-12"
      style="max-width: 425px"
    >
      <div
        class="flex items-start mobile-flex mr2 mobile-mr0 first-flex-container-rev mobile-justify"
      >
        <div class="mr2 mobile-mr2">
          <div class="avatar">
            <p
              class="helvetica-bold avatar-initial"
              style="color: #000d9f; font-size: 30px"
            >
              {{ review.reviewerName[0].toUpperCase() }}
            </p>
          </div>
        </div>
        <div
          class="flex flex-column items-start mobile-flex mobile-flex-column md-pt-4px"
        >
          <p class="bold open-sans-bold namePerson">
            {{ review.reviewerName }}, {{ review.ProductModel }}
          </p>
          <div
            class="flex items-center mobile-flex mobile-mb1 mobile-mt1 review-item-rating"
            style="
              gap: 14.5px;
              transform: scale(0.33);
              transform-origin: left;
              width: 0px;
            "
          >
            <Star :strokeWidth="2" :fill="splitRating[0]" clipId="1" />
            <Star :strokeWidth="2" :fill="splitRating[1]" clipId="2" />
            <Star :strokeWidth="2" :fill="splitRating[2]" clipId="3" />
            <Star :strokeWidth="2" :fill="splitRating[3]" clipId="4" />
            <Star :strokeWidth="2" :fill="splitRating[4]" clipId="5" />
            <p
              class="bold open-sans-bold color-14 ml1 size-numbers-stars mobile-mt0"
              style="margin-left: 3.5px"
            >
              {{ review.feedbackRating }}
            </p>
          </div>
          <div class="flex flex-column" style="gap: 4px">
            <p
              v-if="isVerifiedBuyer"
              class="open-sans-regular color-14 text-with-icon flex items-center mobile-mb0 text-icon-size"
            >
              <i class="icon-verified mr1"
                ><svg
                  width="18"
                  height="18"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.96796 1.45599C7.5217 0.848003 8.47828 0.848003 9.03201 1.45599L9.74404 2.23783C10.0238 2.54511 10.4261 2.7117 10.8413 2.6923L11.8976 2.64294C12.719 2.60454 13.3955 3.281 13.3571 4.10244L13.3077 5.15876C13.2883 5.57391 13.4549 5.97611 13.7622 6.25594L14.544 6.96796C15.152 7.5217 15.152 8.47828 14.544 9.03201L13.7622 9.74404C13.4549 10.0238 13.2883 10.4261 13.3077 10.8413L13.3571 11.8976C13.3955 12.719 12.719 13.3955 11.8976 13.3571L10.8413 13.3077C10.4261 13.2883 10.0238 13.4549 9.74404 13.7622L9.03201 14.544C8.47828 15.152 7.5217 15.152 6.96796 14.544L6.25594 13.7622C5.97611 13.4549 5.57391 13.2883 5.15876 13.3077L4.10243 13.3571C3.281 13.3955 2.60454 12.719 2.64294 11.8976L2.6923 10.8413C2.7117 10.4261 2.54511 10.0238 2.23783 9.74404L1.45599 9.03201C0.848003 8.47828 0.848003 7.5217 1.45599 6.96796L2.23783 6.25594C2.54511 5.97611 2.7117 5.57391 2.6923 5.15876L2.64294 4.10243C2.60454 3.281 3.281 2.60454 4.10244 2.64294L5.15876 2.6923C5.57391 2.7117 5.97611 2.54511 6.25594 2.23783L6.96796 1.45599Z"
                    stroke="#555D65"
                  />
                  <path
                    d="M5.90723 7.99962L7.30308 9.39547L10.0948 6.60376"
                    stroke="#555D65"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  /></svg></i
              >{{ $t("reviews_badge_verified_buyer") }}
            </p>
            <p
              v-if="review.isRecommended"
              class="open-sans-regular color-14 flex items-center text-icon-size"
            >
              <i class="icon-recommend mr1">
                <svg
                  width="17"
                  height="20"
                  viewBox="0 0 14 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.5288 6.51745H9.05825V3.873C9.05792 3.52244 8.92767 3.18633 8.69608 2.93844C8.46449 2.69055 8.15048 2.55113 7.82296 2.55078H6.94466C6.74666 2.55168 6.55553 2.62853 6.40594 2.76737C6.25636 2.90622 6.15823 3.09787 6.12937 3.30753L5.78143 5.9154L4.30854 8.28041H1.64648V14.4508H10.2935C11.0577 14.4498 11.7904 14.1245 12.3307 13.5461C12.8711 12.9677 13.175 12.1836 13.1759 11.3656V8.28041C13.1754 7.81302 13.0016 7.36495 12.6929 7.03445C12.3841 6.70396 11.9655 6.51803 11.5288 6.51745ZM4.11707 13.5693H2.47001V9.16189H4.11707V13.5693ZM12.3524 11.3656C12.3517 11.9498 12.1346 12.51 11.7486 12.9231C11.3627 13.3362 10.8394 13.5686 10.2935 13.5693H4.9406V8.8547L6.57037 6.23802L6.94507 3.43226H7.82296C7.93216 3.43226 8.0369 3.4787 8.11412 3.56135C8.19134 3.64401 8.23472 3.75611 8.23472 3.873V7.39893H11.5288C11.7472 7.39916 11.9565 7.49211 12.1109 7.65737C12.2653 7.82263 12.3521 8.0467 12.3524 8.28041V11.3656Z"
                    fill="#555D65"
                  />
                </svg>
              </i>
              {{ $t("reviews_badge_would_recommend") }}
            </p>
            <p
              v-if="isSweepstakeReview"
              class="open-sans-regular color-14 flex items-center text-icon-size"
            >
              <i class="icon-recommend mr1">
                <svg
                  width="16"
                  height="15"
                  viewBox="0 0 16 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.6582 2.00391L2.92728 8.75736L3.63439 9.46447C3.63439 9.46447 4.69505 9.11091 5.75571 10.1716C6.81637 11.2322 6.46282 12.2929 6.46282 12.2929L7.16992 13L13.9008 6.24655L13.1937 5.53944C13.1937 5.53944 12.1331 5.89299 11.0724 4.83233C10.0118 3.77167 10.3653 2.71101 10.3653 2.71101L9.6582 2.00391Z"
                    fill="white"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.65737 1.00406C9.39186 1.00428 9.13734 1.11008 8.94992 1.29813L2.21899 8.05159C1.82958 8.4423 1.83011 9.07455 2.22017 9.46462L2.92728 10.1717C3.18821 10.4327 3.57094 10.5281 3.92275 10.4221C3.92836 10.4209 3.94041 10.4184 3.95811 10.4159C4.00452 10.4093 4.08463 10.4029 4.18687 10.4143C4.3752 10.4352 4.69004 10.5203 5.0486 10.8788C5.40716 11.2374 5.4922 11.5522 5.51313 11.7406C5.52449 11.8428 5.51817 11.9229 5.51154 11.9693C5.50901 11.987 5.50654 11.9991 5.50529 12.0047C5.39929 12.3565 5.49478 12.7392 5.75571 13.0002L6.46282 13.7073C6.65056 13.895 6.90525 14.0004 7.17076 14.0002C7.43626 13.9999 7.69078 13.8941 7.87821 13.7061L14.6091 6.95262C14.9985 6.56191 14.998 5.92966 14.608 5.53959L13.9008 4.83249C13.6399 4.57156 13.2572 4.47607 12.9054 4.58207C12.8998 4.58331 12.8877 4.58578 12.87 4.58831C12.8236 4.59494 12.7435 4.60126 12.6413 4.5899C12.4529 4.56898 12.1381 4.48394 11.7795 4.12538C11.421 3.76682 11.3359 3.45198 11.315 3.26365C11.3036 3.1614 11.31 3.0813 11.3166 3.03489C11.3191 3.01718 11.3216 3.00513 11.3228 2.99952C11.4288 2.64771 11.3333 2.26498 11.0724 2.00406L10.3653 1.29695C10.1776 1.10921 9.92287 1.00384 9.65737 1.00406ZM10.3653 2.71116C10.3653 2.71116 10.0118 3.77182 11.0724 4.83248C12.1331 5.89314 13.1937 5.53959 13.1937 5.53959L13.9008 6.2467L7.16992 13.0002L6.46282 12.293C6.46282 12.293 6.81637 11.2324 5.75571 10.1717C4.69505 9.11106 3.63439 9.46462 3.63439 9.46462L2.92728 8.75751L9.6582 2.00406L10.3653 2.71116Z"
                    fill="#555D65"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.88676 4.47555C8.08202 4.28029 8.3986 4.28029 8.59386 4.47555L11.4223 7.30398C11.6176 7.49924 11.6176 7.81582 11.4223 8.01108C11.227 8.20634 10.9104 8.20634 10.7152 8.01108L7.88676 5.18265C7.69149 4.98739 7.69149 4.67081 7.88676 4.47555Z"
                    fill="#555D65"
                  />
                  <path
                    d="M13.1699 8H1.16992V9C1.16992 9 2.16992 9.5 2.16992 11C2.16992 12.5 1.16992 13 1.16992 13V14H13.1699V13C13.1699 13 12.1699 12.5 12.1699 11C12.1699 9.5 13.1699 9 13.1699 9V8Z"
                    fill="white"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M14.1699 8C14.1699 7.44772 13.7222 7 13.1699 7H1.16992C0.617637 7 0.169922 7.44772 0.169922 8V9C0.169922 9.369 0.373029 9.70715 0.696748 9.88097C0.701591 9.88405 0.711864 9.89083 0.726171 9.90156C0.763678 9.92969 0.82479 9.98187 0.889053 10.0622C1.00743 10.2102 1.16992 10.4929 1.16992 11C1.16992 11.5071 1.00743 11.7898 0.889053 11.9378C0.82479 12.0181 0.763678 12.0703 0.726171 12.0984C0.711864 12.1092 0.701593 12.1159 0.696749 12.119C0.373029 12.2928 0.169922 12.631 0.169922 13V14C0.169922 14.5523 0.617637 15 1.16992 15H13.1699C13.7222 15 14.1699 14.5523 14.1699 14V13C14.1699 12.631 13.9668 12.2928 13.6431 12.119C13.6382 12.1159 13.628 12.1092 13.6137 12.0984C13.5762 12.0703 13.5151 12.0181 13.4508 11.9378C13.3324 11.7898 13.1699 11.5071 13.1699 11C13.1699 10.4929 13.3324 10.2102 13.4508 10.0622C13.5151 9.98187 13.5762 9.92969 13.6137 9.90156C13.628 9.89083 13.6383 9.88405 13.6431 9.88097C13.9668 9.70715 14.1699 9.369 14.1699 9V8ZM13.1699 9C13.1699 9 12.1699 9.5 12.1699 11C12.1699 12.5 13.1699 13 13.1699 13V14H1.16992V13C1.16992 13 2.16992 12.5 2.16992 11C2.16992 9.5 1.16992 9 1.16992 9V8H13.1699V9Z"
                    fill="#555D65"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.1699 8.5C10.4461 8.5 10.6699 8.72386 10.6699 9V13C10.6699 13.2761 10.4461 13.5 10.1699 13.5C9.89378 13.5 9.66992 13.2761 9.66992 13V9C9.66992 8.72386 9.89378 8.5 10.1699 8.5Z"
                    fill="#555D65"
                  />
                </svg>
              </i>
              {{ $t("reviews_badge_sweepstake") }}
            </p>
          </div>
        </div>
      </div>
      <!-- <p class="open-sans-regular color-14 mt2 size-text-card md-block hide">{{ $t('reviews_item_auto_translated') }}</p> -->
    </div>

    <div
      class="flex flex-column items-start mobile-flex mobile-flex-column mt2 md-mt0 col-12"
    >
      <p class="h3 helvetica-bold" style="line-height: 24px">
        {{ review.reviewTitle }}
      </p>
      <p
        class="open-sans-regular color-14 mt1 h5"
        v-if="review.reviewText.length <= cutOffLength"
      >
        {{ review.reviewText }}
      </p>
      <p
        class="open-sans-regular color-14 mt1 h5"
        v-if="review.reviewText.length > cutOffLength"
        v-html="reviewTextExpanded ? review.reviewText : trimmedReviewText"
      ></p>
      <p
        v-if="review.reviewText.length > cutOffLength && !reviewTextExpanded"
        @click="reviewTextExpanded = true"
        class="open-sans-regular color-1 flex items-center pointer h5"
        style="margin-top: 12px"
      >
        {{ $t("reviews_item_read_more") }}
        <svg
          width="11"
          class="icon-down mx1"
          height="7"
          viewBox="0 0 11 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.58594 1L5.29265 6L0.999353 1"
            stroke="#1867FF"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </p>
      <p
        v-if="review.reviewText.length > cutOffLength && reviewTextExpanded"
        @click="reviewTextExpanded = false"
        class="open-sans-regular color-1 mt1 flex items-center pointer h5"
      >
        {{ $t("reviews_item_read_less") }}
        <svg
          class="icon-down mx1"
          style="transform: rotate(180deg)"
          width="11"
          height="7"
          viewBox="0 0 11 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.58594 1L5.29265 6L0.999353 1"
            stroke="#1867FF"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </p>

      <!-- desktop media markup -->
      <div class="md-flex mt2 gap1 col-12 hide">
        <div
          v-for="(file, index) in media"
          :key="index"
          v-if="index < 4 && review.photos.length > 0"
          class="review-img-container relative col-12"
        >
          <img
            class="absolute-img cover pointer"
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            "
            :src="
              file.isVideo
                ? 'https://static.redseafish.com/wp-content/uploads/2023/10/10132929/reviews_video_placeholder_thumbnail.jpg'
                : file.small != ''
                ? file.small
                : file.medium != ''
                ? file.medium
                : file.large != ''
                ? file.large
                : ''
            "
            @click="
              openMediaModal(
                media,
                index,
                review.reviewTitle,
                review.reviewerName,
                modelName
              )
            "
          />
          <svg
            v-if="file.isVideo"
            style="
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              pointer-events: none;
            "
            width="48"
            height="48"
            viewBox="0 0 63 63"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="31.5" cy="31.5" r="31" stroke="white"></circle>
            <path d="M46 32L25 44.1244L25 19.8756L46 32Z" fill="white"></path>
          </svg>
        </div>
        <div
          v-if="media.length > 3"
          class="review-img-container relative col-12 preview-img-overlay"
        >
          <div
            class="preview-img-count helvetica-semibold color-white pointer-events-none"
            style="font-size: 26.57px"
          >
            +{{ media.length - 5 }}
          </div>
          <img
            class="absolute-img cover pointer"
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            "
            :src="
              media[3].isVideo
                ? 'https://static.redseafish.com/wp-content/uploads/2023/10/10132929/reviews_video_placeholder_thumbnail.jpg'
                : media[3].small != ''
                ? media[3].small
                : media[3].medium != ''
                ? media[3].medium
                : media[3].large != ''
                ? media[3].large
                : ''
            "
            @click="
              openMediaModal(
                media,
                3,
                review.reviewTitle,
                review.reviewerName,
                modelName
              )
            "
          />
          <svg
            v-if="media[3].isVideo"
            style="
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              pointer-events: none;
            "
            width="48"
            height="48"
            viewBox="0 0 63 63"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="31.5" cy="31.5" r="31" stroke="white"></circle>
            <path d="M46 32L25 44.1244L25 19.8756L46 32Z" fill="white"></path>
          </svg>
        </div>
      </div>

      <!-- mobile media markup -->
      <div class="md-hide mt2 gap2 col-12 flex">
        <div
          v-if="review.photos.length > 0"
          class="review-img-container relative col-12"
          style="max-height: 196px"
        >
          <img
            v-if="!media.isVideo"
            class="absolute-img cover pointer"
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            "
            :src="
              media[0].isVideo
                ? 'https://static.redseafish.com/wp-content/uploads/2023/10/10132929/reviews_video_placeholder_thumbnail.jpg'
                : media[0].small != ''
                ? media[0].small
                : media[0].medium != ''
                ? media[0].medium
                : media[0].large != ''
                ? media[0].large
                : ''
            "
            @click="
              openMediaModal(
                media,
                0,
                review.reviewTitle,
                review.reviewerName,
                modelName
              )
            "
          />
          <svg
            v-if="media[0].isVideo"
            style="
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              pointer-events: none;
            "
            width="48"
            height="48"
            viewBox="0 0 63 63"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="31.5" cy="31.5" r="31" stroke="white"></circle>
            <path d="M46 32L25 44.1244L25 19.8756L46 32Z" fill="white"></path>
          </svg>
        </div>

        <div
          v-if="review.photos.length == 2"
          class="review-img-container relative col-12"
          style="max-height: 196px"
        >
          <img
            class="absolute-img cover pointer"
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            "
            :src="
              media[1].isVideo
                ? 'https://static.redseafish.com/wp-content/uploads/2023/10/10132929/reviews_video_placeholder_thumbnail.jpg'
                : media[1].small != ''
                ? media[1].small
                : media[1].medium != ''
                ? media[1].medium
                : media[1].large != ''
                ? media[1].large
                : ''
            "
            @click="
              openMediaModal(
                media,
                1,
                review.reviewTitle,
                review.reviewerName,
                modelName
              )
            "
          />
          <svg
            v-if="media[1].isVideo"
            style="
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              pointer-events: none;
            "
            width="48"
            height="48"
            viewBox="0 0 63 63"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="31.5" cy="31.5" r="31" stroke="white"></circle>
            <path d="M46 32L25 44.1244L25 19.8756L46 32Z" fill="white"></path>
          </svg>
        </div>

        <div
          v-if="review.photos.length > 2"
          class="flex flex-column col-12 gap2"
          style="max-width: 90px"
        >
          <div
            class="review-img-container relative col-12"
            style="max-height: 196px"
          >
            <img
              v-if="!media[1].isVideo"
              class="absolute-img cover pointer"
              style="
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
              "
              :src="
                media[1].isVideo
                  ? 'https://static.redseafish.com/wp-content/uploads/2023/10/10132929/reviews_video_placeholder_thumbnail.jpg'
                  : media[1].small != ''
                  ? media[1].small
                  : media[1].medium != ''
                  ? media[1].medium
                  : media[1].large != ''
                  ? media[1].large
                  : ''
              "
              @click="
                openMediaModal(
                  media,
                  1,
                  review.reviewTitle,
                  review.reviewerName,
                  modelName
                )
              "
            />
            <svg
              v-if="media[1].isVideo"
              style="
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                pointer-events: none;
              "
              width="48"
              height="48"
              viewBox="0 0 63 63"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="31.5" cy="31.5" r="31" stroke="white"></circle>
              <path d="M46 32L25 44.1244L25 19.8756L46 32Z" fill="white"></path>
            </svg>
          </div>
          <div
            class="review-img-container relative col-12 preview-img-overlay pointer"
            style="max-height: 196px"
          >
            <div
              class="preview-img-count helvetica-semibold color-white pointer-events-none"
              style="font-size: 26.57px"
            >
              +{{ media.length - 2 }}
            </div>
            <img
              v-if="!media[2].isVideo"
              class="absolute-img cover"
              style="
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
              "
              :src="
                media[2].isVideo
                  ? 'https://static.redseafish.com/wp-content/uploads/2023/10/10132929/reviews_video_placeholder_thumbnail.jpg'
                  : media[2].small != ''
                  ? media[2].small
                  : media[2].medium != ''
                  ? media[2].medium
                  : media[2].large != ''
                  ? media[2].large
                  : ''
              "
              @click="
                openMediaModal(
                  media,
                  2,
                  review.reviewTitle,
                  review.reviewerName,
                  modelName
                )
              "
            />
            <svg
              v-if="media[2].isVideo"
              style="
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                pointer-events: none;
              "
              width="48"
              height="48"
              viewBox="0 0 63 63"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="31.5" cy="31.5" r="31" stroke="white"></circle>
              <path d="M46 32L25 44.1244L25 19.8756L46 32Z" fill="white"></path>
            </svg>
          </div>
        </div>
      </div>

      <p
        class="open-sans-semibold mt2 color-14 size-text-card"
        v-if="helpfulCount > 0"
        style="font-size: 12px; margin-bottom: 6px"
      >
        {{ helpfulCount }} {{ $t("reviews_item_helpful_line") }}
      </p>
      <div
        :class="`flex mobile-flex justify-between col-12 items-center ${
          helpfulCount > 0 ? 'mt1' : 'mt2'
        }`"
      >
        <div class="flex gap1">
          <button
            type="button"
            style="font-size: 12px"
            :class="`open-sans-semibold border rounded button-bordered mobile-px2 pointer helpful-btn ${
              markedHelpful ? 'color-white bg-color-black' : 'color-14'
            }`"
            @click="markedHelpful ? markNeutral() : markHelpful()"
          >
            {{ $t("reviews_item_helpful") }}
          </button>
          <button
            type="button"
            style="font-size: 12px"
            :class="`open-sans-semibold p1 color-14 mobile-px2 pointer ${
              markedUnhelpful ? 'open-sans-bold underline' : ''
            }`"
            @click="markedUnhelpful ? markNeutral() : markUnhelpful()"
          >
            {{ $t("reviews_item_not_helpful") }}
          </button>
        </div>
        <p
          class="open-sans-semibold color-14"
          style="font-size: 12px; -webkit-font-smoothing: antialiased"
        >
          {{ reviewDate }}
        </p>
      </div>

      <!-- RedSea comment -->
      <div
        class="flex flex-column gap2 mt3"
        v-if="review.clientResponse.length > 0"
      >
        <div
          class="flex mobile-flex items-center"
          v-for="(comment, index) in review.clientResponse"
          :key="index"
        >
          <div class="vertical" style="margin-right: 20px"></div>
          <div class="">
            <p class="open-sans-bold h6">
              <i class="icon-reply" style="margin-right: 4px"
                ><svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_2031_44032)">
                    <path
                      d="M14.667 8.66797L10.0003 14.668L10.0003 11.3346C2.03166 11.3346 1.11233 4.88263 1.33366 1.33464C1.66833 3.12463 1.82366 6.0013 10.0003 6.0013L10.0003 2.66797L14.667 8.66797Z"
                      stroke="black"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2031_44032">
                      <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(16 16) rotate(-180)"
                      />
                    </clipPath>
                  </defs></svg></i
              >{{ $t("reviews_item_reply_from_redsea") }}
            </p>
            <p class="open-sans-regular color-14 h5" style="padding-top: 2px">
              {{ comment.response }}
            </p>
          </div>
        </div>
      </div>

      <!-- TODO: logic for autop translation notice display -->
      <!-- <p class="open-sans-regular color-14 mt2 size-text-card md-hide block">{{ $t('reviews_item_auto_translated') }}</p> -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { EventBus } from "../event-bus";
import Star from "./Star";

export default {
  name: "ReviewItem",
  props: {
    review: Object,
    openMediaModal: Function,
    modelName: String,
  },
  components: {
    Star,
  },
  data() {
    return {
      star1Fill: 0,
      star2Fill: 0,
      star3Fill: 0,
      star4Fill: 0,
      star5Fill: 0,
      reviewTextExpanded: false,
      cutOffLength: 170,
      markedHelpful: false,
      markedUnhelpful: false,
      helpfulCount: this.review.totalFeedbackCount,
    };
  },
  computed: {
    isVerifiedBuyer() {
      for (let i = 0; i < this.review.badges.length; i++) {
        if (this.review.badges[i] == "verifiedPurchaser") {
          return true;
        } else {
        }
      }
      return false;
    },
    isSweepstakeReview() {
      for (let i = 0; i < this.review.badges.length; i++) {
        if (this.review.badges[i] == "SweepstakeReview") {
          return true;
        } else {
        }
      }
      return false;
    },
    splitRating() {
      let arr = [];
      let totalRating = this.review.feedbackRating * 20;

      //split / 20 * 100 = rest

      let v1 = totalRating - 20;
      if (v1 >= 0) {
        arr[0] = 100;
      } else {
        arr[0] = (totalRating / 20) * 100;
        return [arr[0], 0, 0, 0, 0];
      }
      let v2 = v1 - 20;
      if (v2 >= 0) {
        arr[1] = 100;
      } else {
        arr[1] = (v1 / 20) * 100;
        return [arr[0], arr[1], 0, 0, 0];
      }
      let v3 = v2 - 20;
      if (v3 >= 0) {
        arr[2] = 100;
      } else {
        arr[2] = (v2 / 20) * 100;
        return [arr[0], arr[1], arr[2], 0, 0];
      }
      let v4 = v3 - 20;
      if (v4 >= 0) {
        arr[3] = 100;
      } else {
        arr[3] = (v3 / 20) * 100;
        return [arr[0], arr[1], arr[2], arr[3], 0];
      }
      let v5 = v4 - 20;
      if (v5 >= 0) {
        arr[4] = 100;
      } else {
        arr[4] = (v4 / 20) * 100;
        return [arr[0], arr[1], arr[2], arr[3], arr[4]];
      }

      return arr;
    },
    trimmedReviewText() {
      let trimmed = "";
      if (this.review.reviewText.length > this.cutOffLength) {
        trimmed =
          this.review.reviewText.substring(0, this.cutOffLength) +
          '<span v-if="!reviewTextExpanded">...</span>';
        return trimmed;
      } else {
        trimmed = "";
        return trimmed;
      }
    },
    media() {
      let arr = [];
      for (let i = 0; i < this.review.photos.length; i++) {
        let photo = {
          isVideo: false,
          large: "",
          medium: "",
          small: "",
        };
        for (let j = 0; j < this.review.photos[i].photo.length; j++) {
          if (this.review.photos[i].photo[j].id == "large") {
            photo.large = this.review.photos[i].photo[j].url;
          }
          if (this.review.photos[i].photo[j].id == "medium") {
            photo.medium = this.review.photos[i].photo[j].url;
          }
          if (this.review.photos[i].photo[j].id == "normal") {
            photo.medium = this.review.photos[i].photo[j].url;
          }
          if (this.review.photos[i].photo[j].id == "thumbnail") {
            photo.small = this.review.photos[i].photo[j].url;
          }
        }
        arr.push(photo);
      }

      for (let x = 0; x < this.review.videos.length; x++) {
        let video = {
          isVideo: true,
          url: this.review.videos[x].videoUrl,
        };
        arr.push(video);
      }

      return arr;
    },
    reviewDate() {
      const dateObj = new Date(this.review.submissionDate);
      const day = dateObj.getDate();
      const month = new Intl.DateTimeFormat("en-US", { month: "long" }).format(
        dateObj
      );
      const year = dateObj.getFullYear();

      const formattedDate = `${day} ${month} ${year}`;

      return formattedDate;
    },
  },
  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    markHelpful() {
      //dev.data.redseafish.com/public-api/review/set-helpfulness/json
      https: axios
        .post(
          process.env.VUE_APP_RSF_API +
            "/public-api/review/set-helpfulness/json",
          {
            reviewId: this.review.reviewId,
            vote: "Positive",
          }
        )
        .then(() => {
          this.markedHelpful = true;
          this.markedUnhelpful = false;
          this.helpfulCount++;
        });
    },
    markUnhelpful() {
      axios
        .post(
          process.env.VUE_APP_RSF_API +
            "/public-api/review/set-helpfulness/json",
          {
            reviewId: this.review.reviewId,
            vote: "Negative",
          }
        )
        .then(() => {
          this.markedHelpful = false;
          this.markedUnhelpful = true;
        });
    },
    markNeutral() {
      // axios
      //   .post(process.env.VUE_APP_RSF_API + '/public-api/review/set-helpfulness/json', {
      //     "reviewId": this.review.reviewId,
      //     "vote": "Negative"
      //   })
      //   .then(() => {
      //     this.markedHelpful = false;
      //     this.markedUnhelpful = true;
      //   })
      if (this.markedHelpful) {
        this.markedHelpful = false;
        this.helpfulCount--;
      }
      if (this.markedUnhelpful) {
        this.markedUnhelpful = false;
      }
    },
  },
};
</script>

<style scoped>
.text-icon-size {
  font-size: 14px;
}

.img-reviews {
  width: 65px;
  height: 65px;
}

.namePerson {
  font-size: 16px;
}

.avatar {
  vertical-align: middle;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: #0012dd70;
  position: relative;
}
.avatar-initial {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/* #000D9F */

.rounded-card-review {
  border-radius: 15px;
  border-color: var(--rsf-2022-color-white) !important;
}

.button-bordered {
  border-color: var(--rsf-2022-color-14);
  color: var(--rsf-2022-color-14);
}

.button-no-bordered {
  color: var(--rsf-2022-color-14);
}

.icon-verified {
  width: 17px;
  height: 17px;
}

.icon-recommend {
  width: 17px;
  height: 17px;
}

.icon-down {
  width: 12px;
}

.first-flex-container-rev {
  flex: none;
}

.icon-reply {
  width: 20px;
}

.size-numbers-stars {
  font-size: 40px;
}

.vertical {
  border-left: 2px solid var(--rsf-2022-color-1);
  height: 100%;
}

.justify-box-rev {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media only screen and (max-width: 600px) {
  .img-reviews-main {
    width: 196px;
    height: 196px;
  }

  .text-icon-size {
    font-size: 12px;
  }

  .img-reviews {
    width: 65px;
    height: 65px;
  }

  .icon-down {
    margin-top: 0.2rem;
    width: 13px;
  }

  .size-numbers-stars {
    font-size: 40px;
  }

  .img-reviews-main {
    width: 196px;
    height: 196px;
  }

  .img-reviews {
    width: 90px;
    height: 90px;
  }

  .icon-down {
    width: 14px;
  }

  .justify-box-rev {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: left;
  }
}

.button-bordered.color-white {
  color: white;
}

.md-pt-4px {
  margin-top: 4px;
}

.review-img-container {
  max-width: 65px;
  aspect-ratio: 1 /1;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 0 1px var(--rsf-2022-color-12);
}

.mb-12 {
  margin-bottom: 12px;
}

@media (max-width: 832px) {
  .review-img-container {
    max-width: none;
  }
  .md-pt-4px {
    margin-top: -1px;
  }
}

.preview-img-overlay {
  background-color: black;
}

.preview-img-overlay img {
  opacity: 0.3;
}

.preview-img-count {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
}

.review-item {
  padding: 1.5rem;
}
.review-item-rating {
  height: 28px;
}

@media (max-width: 600px) {
  .review-item-rating {
    height: 14px;
  }
}

.helpful-btn {
  padding: 4px 11px;
}
</style>
