<template>
  <div style="min-height: 100vh">
    <div class="px2 border-box md-py4 mobile-px2 py3">
      <div class="center h0 helvetica-bold">
        {{ title }}
      </div>
      <div
        :class="`center h0 open-sans-bold ${
          modelsList.length > 1 ? 'mb4' : 'mb1'
        }`"
      >
        {{ subtitle }}
      </div>

      <!-- Select input with chevron -->
      <div
        v-show="modelsList.length > 1"
        class="rsf-select-wrapper mx-auto relative col-12 max-width-3 mb3 mobile-mx-auto"
      >
        <svg
          width="12"
          height="8"
          viewBox="0 0 12 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.7695 1.5L5.88481 6.5L1.00008 1.5"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <select
          class="rsf-select h5 color-black open-sans-regular pointer reviews-product-select"
          @change="
            fetchReviews(selectedModel.RNumber);
            showImage = false;
          "
          v-model="selectedModel"
        >
          <option
            v-for="model in modelsList"
            :key="model.RNumber"
            :value="model"
          >
            {{ model.Model }}
          </option>
        </select>
      </div>
      <!--This is the image displayed-->
      <div class="flex flex-column items-center">
        <div
          v-show="!showImage"
          class="flex items-center"
          style="height: 450px"
        >
          <img
            class="block center mx-auto my3"
            src="https://static.redseafish.com/wp-content/uploads/2023/09/07130304/spinner2.gif"
          />
        </div>
        <img
          class="fit contain"
          v-show="showImage"
          @load="showImage = true"
          style="aspect-ratio: 1/1; height: 450px"
          :src="selectedModel.mainImage"
        />
      </div>

      <!-- This is the star rating component :) -->
      <div class="md-grid-3-col grid-1-col max-width-5 mx-auto">
        <div></div>
        <rating
          :reviewCount="reviewCount"
          :rating="Math.round(avgRating * 10) / 10"
          :reviews="reviews"
          :loadingReviews="isLoading"
        />
        <!-- Button & how to invoke Modal -->
        <div class="flex flex-column justify-center md-items-end items-center">
          <div
            @click="$refs['add-review'].open()"
            v-if="showWriteReviewBtn == 'true'"
            class="rsf-btn rsf-btn-secondary color-1 bg-color-white mobile-mb1 h-fit-content"
          >
            {{ $t("reviews_write_review") }}
          </div>
        </div>
      </div>
    </div>

    <div class="px2 py3 bg-color-11 mobile-px2 mobile-py3">
      <!-- <p class="mb3 text-right open-sans-regular color-black right-align max-width-5 mx-auto" style="font-size: 12px;">{{$t('reviews_automatically_translated')}}</p> -->
      <div
        class="flex-column gap3 mb3 bg-color-11"
        v-if="reviews.length > 0 && !isLoading"
      >
        <review-item
          v-if="
            moreReviewsLoaded
              ? index >= 0 && index <= displayCount - 1
              : index < displayCount
          "
          v-for="(review, index) in reviews"
          :key="review.reviewId"
          :review="review"
          :openMediaModal="openMediaModal"
          :modelName="selectedModel.Model"
        />
      </div>

      <div
        class="flex-column gap3 mb3 pt3"
        v-if="reviews.length == 0 && !isLoading && selectedModel.RNumber != ''"
      >
        <!-- <p class="center h5 open-sans-regular mb1">{{ $t('reviews_no_reviews') }}</p>
        <h2 class="center h2 open-sans-bold mb3">{{ $t('reviews_be_the_first') }}</h2> -->
        <h2 class="center h2 open-sans-bold mb3">
          {{ $t("reviews_no_reviews") }}
        </h2>
        <div
          v-if="showWriteReviewBtn == 'true'"
          @click="$refs['add-review'].open()"
          class="rsf-btn rsf-btn-secondary color-1 bg-color-tranpsarent mx-auto mb3 mobile-mb1"
        >
          {{ $t("reviews_write_review") }}
        </div>
      </div>
      <div v-if="isLoading" class="flex-column gap3 mb3">
        <img
          class="block center mx-auto my3"
          src="https://static.redseafish.com/wp-content/uploads/2023/09/07130304/spinner2.gif"
        />
      </div>
      <div
        v-show="reviews.length > 5 && displayCount <= reviews.length"
        style="text-decoration-thickness: 1px"
        class="rsf-btn rsf-btn-tertiary color-1 mx-auto"
        @click="
          moreReviewsLoaded = true;
          displayCount = displayCount + showReviewsIncrement;
        "
      >
        <span class="open-sans-semibold h5">{{ $t("reviews_load_more") }}</span>
        <svg
          width="12"
          height="8"
          viewBox="0 0 12 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11 1.4502L6 6.4502L1 1.45019"
            stroke="#1867FF"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>

    <!-- Modal - give it a ref to open -->
    <rsf-modal ref="add-review">
      <write-review
        :siteLanguage="siteLanguage"
        :modelsList="modelsList"
        :getModalRef="() => $refs['add-review']"
      />
    </rsf-modal>

    <carousel-modal ref="media-modal" />
  </div>
</template>

<script>
import axios from "axios";
import { EventBus } from "../event-bus";
import RSFModal from "./RSFModal.vue";
import ReviewItem from "./ReviewItem.vue";
import DropFile from "./DropFile.vue";
import Rating from "./Rating.vue";
import WriteReview from "./WriteReview.vue";
import CarouselModal from "./CarouselModal.vue";

export default {
  name: "ReviewsApp",
  data() {
    return {
      selectedModel: { Model: "", RNumber: "" },
      reviews: [],
      displayCount: 5,
      showReviewsIncrement: 5,
      moreReviewsLoaded: false,
      isLoading: false,
      modelsList: [],
      avgRating: 0,
      showImage: false,
      showWriteReviewBtn: process.env.VUE_APP_SHOW_ADD_REVIEW_BTN,
      updateImgSrc: false,
    };
  },
  props: {
    siteLanguage: String,
    title: String,
    subtitle: String,
    rNumbers: String,
  },
  components: {
    "rsf-modal": RSFModal,
    "review-item": ReviewItem,
    "drop-zone": DropFile,
    rating: Rating,
    "write-review": WriteReview,
    "carousel-modal": CarouselModal,
  },
  mounted() {
    console.log("mounted");
    this.fetchModels();
  },
  methods: {
    fetchModels() {
      let URLExtension =
        "/public-api/review/get-products-selection-with-inital-data/json";
      axios
        .post(process.env.VUE_APP_RSF_API + URLExtension, {
          RNumberList: this.RNumberList,
        })
        .then((response) => {
          this.modelsList = response.data.RNumbersProductList;

          //if a url is set
          if (Boolean(this.$route.params.model)) {
            console.log("fetchModels route param model detected");
            console.log(this.$route.params.model);
            let foundModel = false;
            for (let i = 0; i < this.modelsList.length; i++) {
              if (this.modelsList[i].RNumber == this.$route.params.model) {
                console.log(
                  "fetchModels route param model in modelsList, select model"
                );
                this.selectedModel = this.modelsList[i];
                this.fetchReviews(this.modelsList[i].RNumber);
                foundModel = true;
                return;
              }
            }
            if (!foundModel) {
              this.selectedModel = this.modelsList[0];
              this.fetchReviews(this.modelsList[0].RNumber);
              const { href } = this.$router.resolve(
                `/${this.siteLanguage}/model=${this.modelsList[0].RNumber}`
              );
              window.history.replaceState({}, null, href);
            }
          } else {
            console.log("no route param set on mount");
            this.selectedModel = this.modelsList[0];
            this.fetchReviews(this.modelsList[0].RNumber);
            const { href } = this.$router.resolve(
              `/${this.siteLanguage}/model=${this.modelsList[0].RNumber}`
            );
            window.history.replaceState({}, null, href);
          }
        });
    },
    fetchReviews(RNumber) {
      console.log("fetchReviews start");

      console.log("PUSH STATE " + RNumber);

      const { href } = this.$router.resolve(
        `/${this.siteLanguage}/model=${RNumber}`
      );
      window.history.replaceState({}, null, href);

      this.isLoading = true;
      let URLExtension =
        "/public-api/review/get-products-selection-with-inital-data/json";
      this.reviews = [];
      axios
        .post(process.env.VUE_APP_RSF_API + URLExtension, {
          RNumberList: [`${RNumber}`],
        })
        .then((response) => {
          if (response.data.reviews != null) {
            this.avgRating = response.data.ratingStars;
            this.reviews = response.data.reviews;
          }
          this.isLoading = false;
        });
    },
    showHiddenReviews() {
      window.alert("This feature is not implemented yet ;)");
    },
    openMediaModal(media, index, reviewTitle, reviewerName, reviewModel) {
      this.$refs["media-modal"].open();
      EventBus.$emit("send_carousel_modal_data", {
        slides: media,
        activeSlide: index,
        reviewerName: reviewerName,
        reviewrModel: reviewModel,
        reviewTitle: reviewTitle,
      });
    },
    updateAppState() {
      let foundModel = false;
      for (let i = 0; i < this.modelsList.length; i++) {
        if (this.modelsList[i].RNumber == this.$route.params.model) {
          this.selectedModel = this.modelsList[i];
          this.fetchReviews(this.modelsList[i].RNumber);
          foundModel = true;
          return;
        }
      }
      // if(!foundModel){
      //   this.selectedModel = this.modelsList[0];
      //   this.fetchReviews(this.modelsList[0].RNumber);
      //   const { href } = this.$router.resolve(`/${this.siteLanguage}/model=${this.modelsList[0].RNumber}`);
      //   window.history.pushState({}, null, href);
      // }
    },
    showLoadedImage() {
      console.log("show image!");
      this.showImage = true;
    },
    redrawImg() {},
  },
  computed: {
    RNumberList() {
      return this.rNumbers.split(",");
    },
    reviewCount() {
      return this.reviews.length;
    },
  },
  watch: {
    "$route.params.model": function () {
      console.log("++++");
      console.log("route params changed");
      console.log("++++");
      this.updateAppState();
    },
    selectedModel() {
      console.log("selectedModel changed");
      let src = this.selectedModel.mainImage;
      console.log(src);
      this.selectedModel.mainImage = "";
      setTimeout(() => {
        this.selectedModel.mainImage = src;
      });
    },
  },
};
</script>

<style scoped>
.size-span-text {
  font-size: 12px;
}
.rsf-btn-blue {
  display: block;
  width: fit-content;
  text-align: center;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  align-items: center;
  white-space: nowrap;
  font-family: "Open Sans Bold", sans-serif;
  padding: 1rem 1.75rem;
  min-width: 180px;
  border-radius: 999px;
  background-color: var(--rsf-2022-color-1);
  color: var(--rsf-2022-color-white);
  box-shadow: none;
  font-size: var(--h5);
  border: none;
}

.rsf-select.reviews-product-select {
  border-color: var(--rsf-2022-color-12) !important;
}

@media (min-width: 52em) {
  .md-items-end {
    align-items: flex-end;
  }
}
</style>
